/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React from 'react';
import { Float, useMask } from '@react-three/drei';
import Brain from '../../Files/LAIN/brain.png';
import { useLoader } from '@react-three/fiber';

export default function Lain({ ...props }) {
  const stencil14 = useMask(14, false);
  const texture = useLoader(THREE.TextureLoader, Brain);

  return (
    <group {...props} dispose={null}>
      <Float rotationIntensity={0} floatIntensity={3} floatingRange={[-0.02, 0.01]} speed={7.5}>
        <mesh>
          <planeBufferGeometry args={[3.12, 3.11]} />
          <meshBasicMaterial map={texture} transparent opacity={1} {...stencil14} />
        </mesh>
      </Float>
    </group>
  );
}
