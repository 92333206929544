/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';
import { useProgress } from '@react-three/drei';
import { config, useSpring } from 'react-spring';
import { useLocation } from 'wouter';
import { CanvasContainer } from './Components/Pages/LandingPage';
import { a as animated } from 'react-spring';

import './index.css';
import './App.css';
import { HtmlWrapper } from './Components/Misc/TextHelper';

function Logo({ progress }) {
  const [location] = useLocation();
  const [startPage, setStartPage] = useState(0);
  const [loaded, setLoaded] = useState(false);

  if (!startPage) {
    setStartPage(location);
  }

  useEffect(() => {
    if (progress === 100) {
      const timerId = setTimeout(() => {
        setLoaded(true);
      }, 250);

      return () => clearTimeout(timerId);
    }
  }, [location, progress, startPage]);

  const faceProps = useSpring({
    opacity: startPage === '/' ? (loaded ? 0 : 1) : 0,
    config: config.slow,
  });
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '45vh',
        zIndex: 10,
        color: 'white',
        pointerEvents: 'none',
      }}
    >
      <animated.img src='/leaf_logo.png' alt='logo' width='50' style={faceProps} />
    </div>
  );
}

function App() {
  const { progress } = useProgress();

  return (
    <div className='home' style={{ width: '100%', display: 'flex' }}>
      <Suspense fallback={null}>
        <Logo progress={progress} />
        <CanvasContainer progress={progress} />
        <HtmlWrapper/>
      </Suspense>
    </div>
  );
}

export default App;
