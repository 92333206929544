import * as THREE from 'three';
import { a, useSpring } from '@react-spring/three';
import React, { useRef } from 'react';
import { useMask, Float } from '@react-three/drei';
import red from '../../Files/Amogus/red.png';
import yellow from '../../Files/Amogus/yellow.png';
import black from '../../Files/Amogus/black.png';

import { useFrame, useLoader } from '@react-three/fiber';

export default function Among({ sect, loaded, width, ...props }) {
  const stencil13 = useMask(13, false);

  const redRotation = useRef();
  const yellowRotation = useRef();

  const red_crew = useLoader(THREE.TextureLoader, red);
  const yellow_crew = useLoader(THREE.TextureLoader, yellow);
  const black_crew = useLoader(THREE.TextureLoader, black);

  const FloatAnim = useSpring({
    loop: true,
    from: { position: [-6, -0.25, 1], rotation: [0, 0, -Math.PI / 2] },
    to: { position: [6, 1.25, 0], rotation: [0, 0, Math.PI / 2] },
    config: { duration: 10000 },
  });

  useFrame((state, delta) => {
    redRotation.current.rotation.z += delta / 2;
    yellowRotation.current.rotation.z -= delta / 5;
  });

  return (
    <group {...props} dispose={null}>
      <a.group {...FloatAnim}>
        <Float rotationIntensity={0} floatIntensity={3} floatingRange={[-0.15, 0.15]} speed={1}>
          <mesh scale={1}>
            <planeBufferGeometry args={[1, 1]} />
            <meshBasicMaterial map={black_crew} transparent opacity={1} {...stencil13} />
          </mesh>
        </Float>
      </a.group>

      <Float
        rotationIntensity={0}
        floatIntensity={3}
        floatingRange={[0.1, 0.2]}
        speed={0.75}
        position={[-1.25, -0.15, 0]}
      >
        <mesh ref={yellowRotation}>
          <planeBufferGeometry args={[1, 1]} />
          <meshBasicMaterial map={yellow_crew} transparent opacity={1} {...stencil13} />
        </mesh>
      </Float>

      <Float
        rotationIntensity={0}
        floatIntensity={2}
        floatingRange={[-0.0, 0.2]}
        speed={0.75}
        position={[0.75, -0.05, 0]}
      >
        <mesh ref={redRotation}>
          <planeBufferGeometry args={[1, 1]} />
          <meshBasicMaterial map={red_crew} transparent opacity={1} {...stencil13} />
        </mesh>
      </Float>
    </group>
  );
}
