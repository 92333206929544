import { a, config, useSpring } from '@react-spring/three';
import React, { useEffect, useRef, useState } from 'react';
import { Text, useMask, Mask, useScroll, useCursor } from '@react-three/drei';
import { useTrail, a as animated } from 'react-spring';
import { useLocation } from 'wouter';
import { useFrame, useThree } from '@react-three/fiber';

import '../../index.css';

function Trail({ paragraph, loaded, loc, heartClicked, vis, contactClicked, children }) {
  const items = React.Children.toArray(children);
  const [opac, setOpac] = useState(false);
  const [t, setT] = useState(false);

  const trail = useTrail(items.length, {
    from: { opacity: 0, x: 10 },
    to: { opacity: t ? 0.9 : 0, x: t ? 0 : 10 },
    config: { mass: 0.25, tension: 250, friction: 50 },
  });

  const containerProps = useSpring({
    opacity: opac ? 1 : 0,
    config: config.slow,
  });

  useEffect(() => {
    if (vis && !(contactClicked || heartClicked || loc !== '/' || !loaded)) {
      setOpac(true);
      setT(true);
    } else {
      setOpac(false);
      setT(false);
    }
  }, [vis, contactClicked, heartClicked, loc, loaded]);

  return (
    <animated.div className='trailerText' style={containerProps}>
      {trail.map(({ x, ...rest }, index) => (
        <animated.div
          key={index}
          className='trailsText'
          style={{
            ...rest,
            transform: x.to((x) => `translate3d(-${x}px, ${x}px, 0)`),
            pointerEvents: vis ? 'all' : 'none',
          }}
        >
          <animated.div style={{}}>{items[index]}</animated.div>
        </animated.div>
      ))}
    </animated.div>
  );
}

function AboutText({
  loaded,
  loc,
  heartClicked,
  width,
  open,
  children,
  vis,
  contactClicked,
  colorReducer,
  reduce,
  ...props
}) {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'center',
        position:'absolute',
      }}
    >
      <div className='textCont' style={{ visibility: loc === '/' ? 'visible' : 'hidden',   }}>
        <Trail loaded={loaded} loc={loc} vis={vis} heartClicked={heartClicked}>
          
          <p key={1}>
            {
              'My name is Sam Pham. Graduated with a bachelors in Computer Science from Missouri University of Science and Technology. I am proficient in both frontend and backend development, but I lean more towards the frontend because of the creative freedom.'
            }
          </p>
          <p key={2}>
            {`I've always enjoyed the arts, and my experience with making digital art paired well with UI/UX design. Though, I didn't get into design intentionally. It was more out of necessity to work on the frontend.`}
          </p>
          <p key={3}>
            {`I enjoy learning and trying new things. I usually pick up a new hobby every few months because of my ADHD. The current flavor of the month: 3D printing.`}
          </p>
        </Trail>
      </div>
    </div>
  );
}

export function IntroText({ width, ...props }) {
  const group = useRef();
  const data = useScroll();
  const stencil13 = useMask(13, false);
  const stencil14 = useMask(14, false);
  const stencil15 = useMask(15, false);

  const [introVisible, setIntroVisible] = useState();

  const IntroFadeAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: introVisible ? 0.95 : 0 },
    config: config.slow,
  });

  useFrame(() => {
    group.current.children[0].material.opacity = 0.95 - data.range(0, 1 / 10);
    group.current.children[1].material.opacity = 0.95 - data.range(0, 2 / 10);
    group.current.children[2].material.opacity = 0.95 - data.range(0, 2 / 10);

    const v = data.visible(0, 0.085);
    if (v !== introVisible) {
      setIntroVisible(v);
    }
  });

  return (
    <group ref={group} {...props}>
      <Text
        textAlign={'left'}
        maxWidth={6}
        anchorX={'center'}
        anchorY={'middle'}
        color={'#17130F'}
        font='/Roboto-Regular.woff'
        fontSize={width ? 0.375 : 0.35}
        letterSpacing={0}
        lineHeight={0.955}
      >
        {`This a collection of some\nof my projects. A mix of\ndesign and development.`}
        <a.meshBasicMaterial {...stencil13} transparent {...IntroFadeAnim} />
      </Text>

      <Text
        textAlign={'left'}
        maxWidth={6}
        anchorX={'center'}
        anchorY={'middle'}
        color={'#C8802C'}
        font='/Roboto-Regular.woff'
        fontSize={width ? 0.375 : 0.35}
        letterSpacing={0}
        lineHeight={0.955}
      >
        {`This a collection of some\nof my projects. A mix of\ndesign and development.`}
        <a.meshBasicMaterial {...stencil14} transparent {...IntroFadeAnim} />
      </Text>

      <Text
        textAlign={'left'}
        maxWidth={6}
        anchorX={'center'}
        anchorY={'middle'}
        color={'#08D310'}
        font='/Roboto-Regular.woff'
        fontSize={width ? 0.375 : 0.35}
        letterSpacing={0}
        lineHeight={0.955}
      >
        {`This a collection of some\nof my projects. A mix of\ndesign and development.`}
        <a.meshBasicMaterial {...stencil15} transparent {...IntroFadeAnim} />
      </Text>
    </group>
  );
}

export function SelectedProjects({ width, heartHover, emailOpened, heartClicked }) {
  const data = useScroll();
  const [isVis, setSelProjVis] = useState(false);
  const stencil11 = useMask(11, false);
  const MaskSlideIn = useSpring({
    from: { scale: [0, 1, 1] },
    to: { scale: isVis && !emailOpened && !heartClicked ? [1, 1, 1] : [0, 1, 1] },
    config: emailOpened || heartClicked ? config.slow : config.molasses,
  });
  const Fade_in1 = useSpring({
    from: { opacity: 0 },
    to: { opacity: isVis && !emailOpened && !heartClicked ? 0.95 : 0 },
    config: { tension: 180, friction: 60.0 },
  });

  const Fade_in2 = useSpring({
    from: { opacity: 0 },
    to: { opacity: isVis && !emailOpened && !heartClicked ? 0.95 : 0 },
    config: config.slow,
    delay: isVis && !emailOpened && !heartClicked ? 400 : 0,
  });

  const Fade_in3 = useSpring({
    from: { opacity: 0 },
    to: { opacity: isVis && !heartClicked ? 1 : 0 },
    config: config.slow,
    delay: isVis && !heartClicked ? 200 : 0,
  });

  useFrame(() => {
    const sp = data.visible(0.6, 1);
    const sp_mob = data.visible(0.75, 1);

    if (width && sp !== isVis) {
      setSelProjVis(sp);
    } else if (!width && sp_mob !== isVis) 
    setSelProjVis(sp_mob);
  });

  return (
    <group position={width ? [-2.5, -12.6, -3] : [-2.75, -12.85, -3]} scale={width ? 1.4 : 1.65}>
      <Text
        textAlign='center'
        anchorX='center'
        anchorY='bottom'
        color={'#17130F'}
        font='/Roboto-Regular.woff'
        fontSize={0.4}
        letterSpacing={-0.01}
        rotation={[0, 0, 0]}
        position={[0, 0.1, -4.35]}
        lineHeight={0.95}
      >
        {`Selected\nProjects`}
        <a.meshBasicMaterial color={'#1D1100'} transparent {...Fade_in1} />
      </Text>

      <Text
        textAlign='center'
        anchorX='center'
        anchorY='top'
        color={'#32271E'}
        font='/Roboto-Regular.woff'
        fontSize={0.2}
        letterSpacing={0.075}
        rotation={[0, 0, 0]}
        position={[-0, -0.15, -4.25]}
      >
        [ Click to view ]
        <a.meshBasicMaterial color={'#251702'} transparent {...Fade_in2} />
      </Text>

      <a.mesh position={[0, 0, -4.25]} {...MaskSlideIn}>
        <planeBufferGeometry args={[1.0, 0.025]} />
        <a.meshBasicMaterial
          color={heartHover ? '#1FDD76' : 'grey'}
          transparent
          {...Fade_in3}
          {...stencil11}
        />
      </a.mesh>

      <a.group>
        <Mask
          id={11}
          colorWrite={false}
          depthWrite={false}
          scale={1}
          rotation={[0, 0, 0]}
          position={[0, 0, -4.25]}
        >
          <planeGeometry args={[1.0, 0.075]} />
        </Mask>
      </a.group>
    </group>
  );
}

function ScrollDown({ width, contactClicked }) {
  const [isVis, setScrollDownVis] = useState(false);
  const data = useScroll();
  const ScrollDown = useSpring({
    from: { opacity: 0 },
    to: { opacity: contactClicked ? 0 : isVis ? 0.75 : 0 },
    config: { tension: 300, friction: 50.0 },
  });

  useFrame(() => {
    const sd = data.visible(0, 0.1);

    if (sd !== isVis) setScrollDownVis(sd);
  });

  return (
    <a.group
      name='LandingPageText'
      rotation={[0, 0, 0]}
      scale={1.35}
      position={width ? [0, -4.05, -0.15] : [0, -3.7, -0.25]}
    >
      <group position={width ? [0, 0, 0] : [0, -1, 0]} scale={width ? 1 : 1.25}>
        <group>
          <Text
            anchorX='center'
            color={'#231E19'}
            font='/Roboto-Regular.woff'
            fontSize={0.2}
            letterSpacing={0.1}
            position={[0, 0, -0.1]}
          >
            Scroll Down
            <a.meshBasicMaterial color={'red'} transparent {...ScrollDown} />
          </Text>

          <Text
            anchorX='center'
            color={'#1D1100'}
            font='/Roboto-Thin.woff'
            fontSize={0.15}
            letterSpacing={0.1}
            position={[0, -0.3, -0.1]}
            scale={[2.15, 1, 1]}
          >
            V
            <a.meshBasicMaterial color={'red'} transparent {...ScrollDown} />
          </Text>
        </group>
      </group>
    </a.group>
  );
}

function Footer({ width, heartClicked, openEmail }) {
  const data = useScroll();
  const { viewport } = useThree();
  const [location] = useLocation();
  const [reachVis, setReachVis] = useState(false);
  const [linkVis, setLinkVis] = useState(false);

  const [hover, setHover] = useState(0);
  const footer_min = Math.min(3.25, viewport.width / 1.5);
  const scale = Math.min(0.65, viewport.width / 7);

  useCursor(hover, 'pointer');

  const handleClick = (url) => {
    if (location === '/') window.open(url, '_blank');
  };

  const handleHover = (bool) => {
    if (location === '/') setHover(bool);
  };

  const ReachFade = useSpring({
    from: { opacity: 0 },
    to: { opacity: reachVis && !heartClicked ? 0.95 : 0 },
    config: !heartClicked ? { tension: 180, friction: 60.0 } : config.slow,
    delay: reachVis && !heartClicked ? 250 : 0,
  });

  const LinkFade = useSpring({
    from: { opacity: 0 },
    to: { opacity: linkVis && !heartClicked ? 1 : 0 },
    config: !heartClicked ? { tension: 180, friction: 60.0 } : config.slow,
    delay: linkVis && !heartClicked ? 250 : 0,
  });

  const CopyFade = useSpring({
    from: { opacity: 0.95 },
    to: { opacity: heartClicked ? 0 : 0.95 },
    config: config.slow,
  });

  useFrame(() => {
    const r = data.visible(0.825, 0.5);
    const r_mob = data.visible(0.75, 0.5);
    const l = data.visible(0.875, 0.5);
    const l_mob = data.visible(0.8, 0.5);

    if (width && r !== reachVis) setReachVis(r);
    else if (!width && r_mob !== reachVis) setReachVis(r_mob);

    if (width && l !== linkVis) setLinkVis(l);
    else if (!width && l_mob !== linkVis) setLinkVis(l_mob);
  });

  return (
    <group position={[0, -18, -2]}>
      <group
        position={width ? [-footer_min, -1, 0] : [-footer_min - 0.15, -0, 0]}
        scale={width ? 1.5 : 1.75}
      >
        <Text
          anchorX={'left'}
          color={'#17130F'}
          font='/Roboto-Regular.woff'
          fontSize={0.45 * scale}
          letterSpacing={-0.04}
          rotation={[0, 0, 0]}
          position={[0, 0.4, 0]}
        >
          Reach out.
          <a.meshBasicMaterial color={'#1D1100'} transparent {...ReachFade} />
        </Text>

        <group scale={1.75 * scale} position={[0, -0.05, 0]}>
          <mesh
            position={[0.35, 0, 0]}
            onPointerDown={() => handleClick('https://www.linkedin.com/in/sp-pham/')}
            onPointerOver={() => handleHover(true)}
            onPointerOut={() => handleHover(false)}
          >
            <planeBufferGeometry args={[0.75, 0.25]} />
            <meshBasicMaterial transparent opacity={0} />
            <Text
              anchorX={'center'}
              color={'#16110D'}
              font='/Roboto-Thin.woff'
              fontSize={0.2}
              letterSpacing={-0.01}
              rotation={[0, 0, 0]}
              position={[0, 0, 0]}
            >
              LinkedIn
              <a.meshBasicMaterial color={'#1D1100'} transparent {...LinkFade} />
            </Text>
          </mesh>

          <mesh
            position={[0.4, -0.4, 0]}
            onPointerOver={() => handleHover(true)}
            onPointerOut={() => handleHover(false)}
            onClick={openEmail}
          >
            <planeBufferGeometry args={[0.8, 0.25]} />
            <meshBasicMaterial transparent opacity={0} />
            <Text
              anchorX='center'
              color={'#16110D'}
              font='/Roboto-Thin.woff'
              fontSize={0.2}
              letterSpacing={-0.01}
              rotation={[0, 0, 0]}
              position={[0, -0, 0]}
            >
              Email me
              <a.meshBasicMaterial color={'#1D1100'} transparent {...LinkFade} />
            </Text>
          </mesh>
        </group>
      </group>

      <Text
        color={'#180E00'}
        font='/Roboto-Thin.woff'
        fontSize={0.2}
        letterSpacing={0.0}
        rotation={[0, 0, 0]}
        position={width ? [0, -1.675, 2.01] : [0, -0.985, 2.01]}
      >
        © Copyright 2023. All rights reserved.
        <a.meshBasicMaterial color={'#1D1100'} transparent {...CopyFade} />
      </Text>
    </group>
  );
}

export default function HomePageText({
  contactClicked,
  heartHover,
  heartClicked,
  width,
  emailOpened,
  openEmail,
  ...props
}) {
  return (
    <>
      <ScrollDown width={width} contactClicked={contactClicked} />
      <SelectedProjects
        width={width}
        heartHover={heartHover}
        emailOpened={emailOpened}
        heartClicked={heartClicked}
      />
      <Footer width={width} heartClicked={heartClicked} openEmail={openEmail} />
    </>
  );
}

export function HtmlHomepageText({ heartClicked, width, contactClicked}) {
  const data = useScroll();
  const [location] = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [isVisible, setVisible] = useState(0);

  useEffect(() => {
    if (location === '/' && !loaded) {
      const timerId = setTimeout(() => {
        setLoaded((prev) => !prev);
      }, 500);
      return () => {
        clearTimeout(timerId);
      };
    } else if (location !== '/' && loaded) {
      setLoaded((prev) => !prev);
    }
  }, [location, loaded]);

  useFrame(() => {
    const v = data.visible(0.1, 0.75);
    if (v !== isVisible) {
      setVisible(v);
    }
  });

  return (
    <AboutText
      vis={isVisible}
      width={width}
      heartClicked={heartClicked}
      contactClicked={contactClicked}
      loc={location}
      loaded={loaded}

    />
  );
}