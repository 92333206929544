import * as THREE from 'three';
import React, { useRef } from 'react';
import { GradientTexture, useMask } from '@react-three/drei';
import img from '../../Files/SolCity/SC_bg.png';
import { useLoader } from '@react-three/fiber';

export default function SolcityBG({ loaded, width, ...props }) {
  const stencil12 = useMask(12, false);
  const group = useRef();
  const texture = useLoader(THREE.TextureLoader, img);

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh scale={[1, 0.65, 1]} position={[0, 0.075, -0.07]}>
        <planeBufferGeometry args={[2.25, 1.5]} />
        <meshBasicMaterial
          color={'#FCEED3'}
          map={texture}
          transparent
          opacity={0.95}
          {...stencil12}
        />
      </mesh>
      <mesh position={[-0.025, -0, -0.1]}>
        <circleBufferGeometry args={[0.75, 64]} />
        <meshBasicMaterial {...stencil12}>
          <GradientTexture
            stops={[0.0, 0.15, 0.65]}
            colors={['#FFA132', '#FF6B00', '#DD2700']}
            size={1000}
          />
        </meshBasicMaterial>
      </mesh>
    </group>
  );
}
