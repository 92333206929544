/* eslint-disable no-unused-vars */
import * as THREE from 'three';
import React, { useRef, useMemo } from 'react';
import { Mask, useMask, useGLTF } from '@react-three/drei';
import { useFrame, useLoader } from '@react-three/fiber';
import { useSpring } from 'react-spring';
import { a, config } from '@react-spring/three';
import { useLocation } from 'wouter';

export default function Petals({ width, ...props }) {
  const [location] = useLocation();
  const { nodes } = useGLTF('models/petals.glb');
  const stencil2 = useMask(2, false);

  const Wind3 = useSpring({
    from: { position: [-1.75, -21.5, 2], rotation: [0, Math.PI / 2, 0], scale: [1, 0.5, 1] },
    to: {
      position: props.projectClick || location === '/projects' ? [0, -5, 5] : [-1.75, -21.5, 5],
      rotation: [0.5, 0.25, -0.1],
      scale: [0.5, 0.5, 0.5],
    },
    config: { tension: 280, friction: 120, duration: 1000 },
    delay: 100,
  });

  const Wind4 = useSpring({
    from: { position: [-1.75, -21.5, 2], rotation: [0, Math.PI / 2, 0], scale: [1, 0.5, 1] },
    to: {
      position: props.projectClick || location === '/projects' ? [0, -12, 6] : [-0, -21.5, 6],
      rotation: [0.5, 0, -0.1],
      scale: [0.5, 0.5, 0.5],
    },
    config: { tension: 280, friction: 120, duration: 1000 },
    delay: 0,
  });
  const Slide = useSpring({
    from: { position: [-3.75, -21.5, 5] },
    to: {
      position:
        props.projectClick || location === '/projects'
          ? width
            ? [-0, -15, 5]
            : [-0, -13, 5]
          : [-3.75, -21.5, 5],
    },
    config: { tension: 280, friction: 120, duration: 700 },

    delay: 500,
  });

  const Opac = useSpring({
    from: { opacity: 0 },
    to: { opacity: props.projectClick ? 1 : 0 },
    config: { duration: 0 },
  });

  return (
    <>
      <group {...props}>
        <a.mesh geometry={nodes.petals.geometry} {...Wind3}>
          <meshBasicMaterial
            color={'#FCEED3'}
            transparent
            opacity={props.projectClick ? 1 : 0}
            {...stencil2}
          />
        </a.mesh>

        <a.mesh geometry={nodes.petals.geometry} {...Wind4}>
          <meshBasicMaterial
            color={'#FCEED3'}
            transparent
            opacity={props.projectClick ? 1 : 0}
            {...stencil2}
          />
        </a.mesh>
      </group>

      <a.mesh {...Slide}>
        <circleBufferGeometry args={[4, 64]} />
        <meshBasicMaterial
          color={'#FCEED3'}
          transparent
          opacity={props.projectClick ? 1 : 0}
          {...stencil2}
        />
      </a.mesh>
    </>
  );
}

useGLTF.preload('models/petals.glb');
