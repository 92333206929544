import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useSpring, a, config } from '@react-spring/three';

export default function Arrow({ loaded, cover, idx, hover, ...props }) {
  const { viewport } = useThree();
  const group = useRef();
  const { nodes } = useGLTF('models/expand.glb');
  const OpacAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded && !cover && hover ? 1 : 0 },
    config: hover ? config.slow : config.stiff,
  });
  return (
    <group
      ref={group}
      {...props}
      rotation={[Math.PI / 2, 0, 0]}
      scale={40}
      position={[Math.min(4.5, viewport.width / 2.275) - 0.8, -idx - 0.2, 0]}
      dispose={null}
    >
      <mesh geometry={nodes.arrow.geometry} position={[0, 0.0005, 0]}>
        <a.meshBasicMaterial color={'#817064'} transparent {...OpacAnim} />
      </mesh>
      <mesh geometry={nodes.box.geometry} position={[0, 0, 0]}>
        <a.meshBasicMaterial color={'#B4A499'} transparent {...OpacAnim} />
      </mesh>
    </group>
  );
}

useGLTF.preload('/expand.glb');
