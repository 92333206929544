import React, { useRef } from 'react';
import { useGLTF, Mask, Edges } from '@react-three/drei';
import { a, config, useSpring } from '@react-spring/three';

export default function HandReach({ invert, mouse, y, heartClicked, heartHover, ...props }) {
  const mesh = useRef();
  const { nodes } = useGLTF('models/hand.glb');

  const EdgeOpacAnim = useSpring({
    from: { opacity: 0.45 },
    to: { opacity: heartClicked ? 0 : 0.45 },
    config: config.molasses,
  });

  return (
    <group ref={mesh} {...props} dispose={null}>
      <group>
        <Mask
          id={2}
          colorWrite={false}
          depthWrite={false}
          geometry={nodes.hand_outline.geometry}
          rotation={[Math.PI / 2, 0, 0]}
          position={[0.5, 3.4, 0]}
          scale={62}
        >
          <Edges threshold={90} scale={0.999} position={[-0, 0, 0]}>
            <a.meshBasicMaterial color={'white'} transparent {...EdgeOpacAnim} />
          </Edges>
        </Mask>
      </group>
    </group>
  );
}

export function HandReachFallback({ invert, mouse, y, aboutClicked, heartHover, ...props }) {
  const mesh = useRef();
  const { nodes } = useGLTF('models/hand_outline.glb');

  return (
    <group ref={mesh} {...props} dispose={null}>
      <a.group>
        <Mask
          id={2}
          colorWrite={false}
          depthWrite={false}
          geometry={nodes.hand_outline.geometry}
          rotation={[Math.PI / 2, 0, 0]}
          position={[0.5, 6, 0]}
          scale={57}
        />
      </a.group>
    </group>
  );
}
