/* eslint-disable no-unused-vars */
import { a, config, useSpring } from '@react-spring/three';
import React from 'react';
import { Mask, useGLTF } from '@react-three/drei';
import { useLocation } from 'wouter';

export default function SlimeMask({ width, ...props }) {
  const { nodes } = useGLTF('models/slime.glb');
  const [location, setLocation] = useLocation();
  const SlideAnim = useSpring({
    from: { position: [0, 2.75, 0] },
    to: { position: location === '/' ? [0, 0, 0] : [0, 2.75, 0] },
    config: location === '/' ? config.molasses : config.stiff,
    delay: location === '/' ? 350 : 0,
  });

  return (
    <group {...props}>
      <a.group {...SlideAnim}>
        <Mask
          id={13}
          geometry={nodes.slime.geometry}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={35}
          position={width ? [0, 0, 5] : [0, 0.1, 6.5]}
        />

        <Mask
          id={14}
          geometry={nodes.slime.geometry}
          rotation={[Math.PI / 2, 0, 0]}
          scale={30}
          position={width ? [0, -0.3, 4] : [0, 0, 5.5]}
        />

        <Mask
          id={15}
          geometry={nodes.slime.geometry}
          rotation={[Math.PI / 2, 0, Math.PI]}
          scale={[50, 0, 25]}
          position={width ? [0, -0.4, 3] : [0, -0.0, 4.5]}
        />
      </a.group>
    </group>
  );
}

useGLTF.preload('models/slime.glb');
