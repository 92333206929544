/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, Mask, Text, useMask } from '@react-three/drei';
import { useSpring, a, config } from '@react-spring/three';

export default function OctoCat({ maskid, loaded, cover, idx, ...props }) {
  const group = useRef();
  const { nodes } = useGLTF('models/octo.glb');
  const stencil = useMask(maskid, false);

  const slide = useSpring({
    loop: true,
    from: { position: [-0.05, 0, 0] },
    to: { position: [0.05, 0, 0] },
    config: {
      duration: 500,
    },
  });

  const OpacAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded && !cover ? 0.35 : 0 },
    config: loaded ? config.molasses : config.stiff,
    delay: !cover ? (loaded ? (idx + 3) * 100 : 0) : 500,
  });

  return (
    <group position={props.position}>
      <group ref={group} scale={props.scale} rotation={props.rotation} dispose={null}>
        <Mask id={maskid} geometry={nodes.octocat.geometry} />
      </group>

      <a.group {...slide}>
        <Text
          position={[3.5, 0.45, -0.01]}
          anchorX='center'
          maxWidth={25}
          anchorY='middle'
          fontSize={0.2}
          lineHeight={1.2}
          letterSpacing={-0.0}
          color={'green'}
          font='/Lato-Bold.woff'
        >
          01001001 01100110 00100000 01111001 01101111 01110101 00100111 01110110 01100101 00100000
          01100111 01101111 01110100 01110100 01100101 01101110 00100000 01110100 01101000 01101001
          01110011 00100000 01100110 01100001 01110010 00101100 00100000 01111001 01101111 01110101
          00100000 01110000 01110010 01101111 01100010 01100001 01100010 01101100 01111001 00100000
          01101011 01101110 01101111 01110111 00100000 01100001 00100000 01101100 01101111 01110100
          00100000 01101101 01101111 01110010 01100101 00100000 01110100 01101000 01100001 01101110
          00100000 01101101 01100101 00101110 00100000 01010100 01101000 01100101 01110010 01100101
          00100111 01110011 00100000 01100001 00100000 01110011 01110100 01101001 01101100 01101100
          00100000 01100001 00100000 01101100 01101111 01110100 00100000 01101111 01100110 00100000
          01110010 01101111 01101111 01101101 00100000 01100110 01101111 01110010 00100000 01101001
          01101101 01110000 01110010 01101111 01110110 01100101 01101101 01100101 01101110 01110100
          00100000 01110111 01101001 01110100 01101000 00100000 01101101 01111001 00100000 01110111
          01101111 01110010 01101011 00101100 00100000 01110011 01101111 00100000 01101001 01100110
          00100000 01111001 01101111 01110101 00100111 01110110 01100101 00100000 01100111 01101111
          01110100 00100000 01110011 01101111 01101101 01100101 00100000 01100001 01100100 01110110
          01101001 01100011 01100101 00101100 00100000 01001001 00100000 01100001 01101101 00100000
          01100001 01101100 01101100 00100000 01100101 01100001 01110010 01110011 00101110 00100000
          <a.meshBasicMaterial transparent {...OpacAnim} {...stencil} />
        </Text>

        <Text
          position={[2, 0.55, -0.1]}
          anchorX='center'
          maxWidth={25}
          anchorY='middle'
          fontSize={0.2}
          lineHeight={1.2}
          letterSpacing={-0.0}
          color={'#5FC369'}
          font='/Lato-Bold.woff'
        >
          01001001 01100110 00100000 01111001 01101111 01110101 00100111 01110110 01100101 00100000
          01100111 01101111 01110100 01110100 01100101 01101110 00100000 01110100 01101000 01101001
          01110011 00100000 01100110 01100001 01110010 00101100 00100000 01111001 01101111 01110101
          00100000 01110000 01110010 01101111 01100010 01100001 01100010 01101100 01111001 00100000
          01101011 01101110 01101111 01110111 00100000 01100001 00100000 01101100 01101111 01110100
          00100000 01101101 01101111 01110010 01100101 00100000 01110100 01101000 01100001 01101110
          00100000 01101101 01100101 00101110 00100000 01010100 01101000 01100101 01110010 01100101
          00100111 01110011 00100000 01100001 00100000 01110011 01110100 01101001 01101100 01101100
          00100000 01100001 00100000 01101100 01101111 01110100 00100000 01101111 01100110 00100000
          01110010 01101111 01101111 01101101 00100000 01100110 01101111 01110010 00100000 01101001
          01101101 01110000 01110010 01101111 01110110 01100101 01101101 01100101 01101110 01110100
          00100000 01110111 01101001 01110100 01101000 00100000 01101101 01111001 00100000 01110111
          01101111 01110010 01101011 00101100 00100000 01110011 01101111 00100000 01101001 01100110
          00100000 01111001 01101111 01110101 00100111 01110110 01100101 00100000 01100111 01101111
          01110100 00100000 01110011 01101111 01101101 01100101 00100000 01100001 01100100 01110110
          01101001 01100011 01100101 00101100 00100000 01001001 00100000 01100001 01101101 00100000
          01100001 01101100 01101100 00100000 01100101 01100001 01110010 01110011 00101110 00100000
          <a.meshBasicMaterial transparent {...OpacAnim} {...stencil} />
        </Text>
      </a.group>
      <mesh position={[0, 0.65, -0.02]}>
        <planeBufferGeometry args={[2, 2]} />
        <a.meshBasicMaterial color={'#BCAF97'} transparent {...OpacAnim} {...stencil} />
      </mesh>
    </group>
  );
}

useGLTF.preload('/octo.glb');
