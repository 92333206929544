/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Base, Depth, Fresnel, LayerMaterial, Noise } from 'lamina';
import { HeartStem } from './Tree';

export default function Heart({ ...props }) {
  const mesh = useRef();
  const { nodes } = useGLTF('models/heart.glb');
  useFrame(({ clock }) => {
    mesh.current.scale.y = 0.925 + 0.0075 * Math.sin(clock.getElapsedTime() * 15);
    mesh.current.scale.x = 0.935 + 0.0075 * Math.sin(clock.getElapsedTime() * 15);
  });

  return (
    <>
      <HeartStem position={[-2.1, -11.5, -5.5]} />
      <group ref={mesh} {...props} dispose={null}>
        <mesh
          geometry={nodes.heart.geometry}
          position={[2.1, -0.0, 0.5]}
          scale={[1.3, 1.3, 1.2]}
          rotation={[-0.03, -0.05, 0]}
        >
          <LayerMaterial>
            <Base color={'#ED3657'} alpha={0.5} mode='normal' />
            <Depth
              colorA={'#E93B30'}
              colorB={'#E93030'}
              alpha={0.99}
              mode='add'
              near={0}
              far={10}
              origin={[0, 10, 0]}
            />
            <Noise
              mapping={'local'}
              type={'simplex'}
              scale={0.45}
              colorA={'#CB1B1B'}
              colorB={'#F1604C'}
              mode={'normal'}
              alpha={0.85}
            />
            <Fresnel
              mode='softlight'
              color='#FFEAD7'
              intensity={1.75}
              power={1.5}
              bias={0.2}
              alpha={0.8}
            />
          </LayerMaterial>
        </mesh>
      </group>
    </>
  );
}

useGLTF.preload('models/hearty.glb');
