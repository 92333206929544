/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { config, useSpring } from '@react-spring/three';
import React, { Suspense, useEffect, useState } from 'react';
import { a as animated, useTrail, useTransition } from 'react-spring';
import { useRef } from 'react';
import { Route, Switch, useLocation } from 'wouter';
import ClickSVG from './Click';
import { ReactComponent as MissingPage } from '../../Files/404.svg';
import '../../index.css';

const Optimization = React.lazy(() => import('../Pages/Optimization'));
const EMACrossover = React.lazy(() => import('../Pages/EMACross'));
const SolCity = React.lazy(() => import('../Pages/SolCity'));
const TaskLogger = React.lazy(() => import('../Pages/TaskLogger'));
const Lain = React.lazy(() => import('../Pages/Lain'));

export function ClickToScroll({ name, trigger }) {
  const opacAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: trigger ? 1 : 0 },
    config: config.slow,
    delay: 500,
  });

  return (
    <>
      <animated.div
        className={`${name}`}
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...opacAnim,
        }}
      >
        <ClickSVG />
      </animated.div>
    </>
  );
}

function ColorDots({ idx }) {
  const [opac, setOpac] = useState(false);
  const opacAnim = useSpring({
    opacity: opac ? 1 : 0,
    config: opac ? config.slow : config.default,
  });

  useEffect(() => {
    if (idx) {
      const timerId = setTimeout(() => {
        setOpac(true);
      }, 750);
      return () => {
        clearTimeout(timerId);
      };
    } else {
      setOpac(false);
    }
  }, [idx]);

  return (
    <animated.div className='dots_container' style={opacAnim}>
      <div className='dot' style={{ backgroundColor: '#95CEDB' }} />
      <div className='dot' style={{ backgroundColor: '#91D99A' }} />
      <div className='dot' style={{ backgroundColor: '#D4AE7F' }} />
    </animated.div>
  );
}

export function WordTrail({ paragraph, name, idx }) {
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const words = paragraph.split(' ');
  const [trigger, setTrigger] = useState(false);

  const trailAnim = useTrail(words.length, {
    from: { opacity: 0, x: -2 },
    to: { opacity: trigger ? 1 : 0, x: trigger ? 0 : -2 },
    config: trigger ? { duration: 50 } : { duration: 10 },
  });

  const opacAnim = useSpring({
    opacity: trigger ? 1 : 0,
    config: trigger ? config.slow : config.default,
  });

  useEffect(() => {
    if (idx) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { root: null, rootMargin: '0px', threshold: 0.001 },
      );

      observer.observe(ref.current);
      if (isIntersecting) {
        const timerId = setTimeout(() => {
          setTrigger(true);
        }, 250);
        return () => {
          clearTimeout(timerId);
          observer.disconnect();
        };
      }
    } else {
      const timerId = setTimeout(() => {
        setTrigger(false);
      }, 250);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [idx, isIntersecting]);

  return (
    <>
      <animated.div ref={ref} className='trailsContainer' style={opacAnim}>
        {trailAnim.map(({ x, ...rest }, index) => (
          <animated.div
            key={index}
            className={name}
            style={{
              ...rest,
              transform: x.to((x) => `translate3d(${x}px, 0, 0)`),
              pointerEvents: trigger ? 'all' : 'none',
            }}
          >
            <animated.div style={{}}>{words[index]}</animated.div>
          </animated.div>
        ))}
      </animated.div>
    </>
  );
}

export function ParagraphTrail({ idx, name, children }) {
  const items = React.Children.toArray(children);
  const [trigger, setTrigger] = useState(false);

  const trail = useTrail(items.length, {
    from: { x: -2 },
    to: { x: trigger ? 0 : 10 },
    config: trigger ? config.default : { duration: 10 },
  });

  const opacAnim = useSpring({
    opacity: trigger ? 1 : 0,
    config: trigger ? config.molasses : config.default,
  });

  const [isIntersecting, setIsIntersecting] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (idx) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { root: null, rootMargin: '0px', threshold: 0.001 },
      );

      observer.observe(ref.current);
      if (isIntersecting) {
        const timerId = setTimeout(() => {
          setTrigger(true);
        }, 250);
        return () => {
          clearTimeout(timerId);
          observer.disconnect();
        };
      }
    } else {
      const timerId = setTimeout(() => {
        setTrigger(false);
      }, 250);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [idx, isIntersecting]);

  return (
    <animated.div ref={ref} className='contentText' style={opacAnim}>
      {trail.map(({ x, ...rest }, index) => (
        <animated.div
          key={index}
          className={name}
          style={{
            ...rest,
            transform: x.to((x) => `translate3d(0, ${x}px, 0)`),
            pointerEvents: trigger ? 'all' : 'none',
          }}
        >
          <animated.div style={{}}>{items[index]}</animated.div>
        </animated.div>
      ))}
    </animated.div>
  );
}

export function Header({ name, paragraph, idx }) {
  const tabbed_paragraph = '      ' + paragraph;

  return (
    <div
      style={{
        height: 'auto',
        width: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10vh',
      }}
    >
      <animated.div
        style={{ height: 'auto', width: '100%', display: 'flex', alignItems: 'center' }}
      >
        <ParagraphTrail name={'project_h1'} idx={idx}>
          <p> {name} </p>
        </ParagraphTrail>
        <ColorDots idx={idx} />
      </animated.div>

      <Divider idx={idx} />

      <div className='pSumContainer'>
        <ParagraphTrail name={'pSumText'} idx={idx}>
          <p> {tabbed_paragraph} </p>
        </ParagraphTrail>
      </div>
    </div>
  );
}

export function Divider({ idx, spaced }) {
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const widthAnim = useSpring({
    from: { x: '0%' },
    to: { x: trigger ? '100%' : '0%' },
    config: trigger ? config.molasses : config.stiff,
  });

  const dividerAnim = useSpring({
    opacity: trigger ? 0.2 : 0,
    config: trigger ? config.molasses : config.stiff,
  });

  useEffect(() => {
    if (idx) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { root: null, rootMargin: '0px', threshold: 0.001 },
      );

      observer.observe(ref.current);
      if (isIntersecting) {
        const timerId = setTimeout(() => {
          setTrigger(true);
        }, 250);
        return () => {
          clearTimeout(timerId);
          observer.disconnect();
        };
      }
    } else {
      const timerId = setTimeout(() => {
        setTrigger(false);
      }, 250);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [idx, isIntersecting]);

  return (
    <>
      <div ref={ref} style={{ width: '100%' }}>
        <animated.div
          className={spaced ? 'spaced_divider' : 'divider_bar'}
          style={{ width: widthAnim.x, ...dividerAnim }}
        />
      </div>
    </>
  );
}

export function Content({ name, paragraph, idx, ...props }) {
  return (
    <>
      <div className='contentContainer'>
        <div className='contentContainerDivTitle'>
          <ParagraphTrail name={'project_h2'} idx={idx}>
            <span> {name} </span>
          </ParagraphTrail>
        </div>

        <div className='contentContainerDiv'>{props.children}</div>
      </div>
    </>
  );
}

export function SplitContent({ idx, pos, ...props }) {
  return (
    <>
      <div className='imageContentContainer' style={{ marginBlock: '0vh' }}>
        {props.children}
      </div>
    </>
  );
}

export function NextButton({ idx, name, next }) {
  const ref = useRef(null);

  const [opac, setOpac] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const opacAnim = useSpring({
    opacity: opac ? 1 : 0,
    config: config.molasses,
  });

  useEffect(() => {
    if (idx) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { root: null, rootMargin: '0px', threshold: 0.001 },
      );

      observer.observe(ref.current);
      if (isIntersecting) {
        const timerId = setTimeout(() => {
          setOpac(true);
        }, 250);
        return () => {
          clearTimeout(timerId);
          observer.disconnect();
        };
      }
    } else {
      const timerId = setTimeout(() => {
        setOpac(false);
      }, 250);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [idx, isIntersecting]);
  return (
    <animated.div
      ref={ref}
      style={{
        userSelect: 'none',
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        bottom: '3vh',
        ...opacAnim,
      }}
    >
      <h3
        className='regRobo'
        style={{ fontSize: '2.95vh', userSelect: 'none', cursor: 'pointer' }}
        onClick={() => next(name.replace(/\s+/g, '').toLowerCase())}
      >
        NEXT:
        <span className='boldRobo' style={{ fontSize: '2.95vh' }}>
          {name}
        </span>
      </h3>
    </animated.div>
  );
}

export function ImageContent({ name, paragraph, idx, ...props }) {
  const ref = useRef(null);
  const [opac, setOpac] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const opacAnim = useSpring({
    opacity: opac ? 1 : 0,
    config: config.molasses,
  });

  useEffect(() => {
    if (idx) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { root: null, rootMargin: '0px', threshold: 0.001 },
      );

      observer.observe(ref.current);
      if (isIntersecting) {
        const timerId = setTimeout(() => {
          setOpac(true);
        }, 250);
        return () => {
          clearTimeout(timerId);
          observer.disconnect();
        };
      }
    } else {
      const timerId = setTimeout(() => {
        setOpac(false);
      }, 250);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [idx, isIntersecting]);

  return (
    <>
      <div
        ref={ref}
        className='imageBannerContainer'
        style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '5vh' }}
      >
        <div className='contentContainerDivTitle' style={{ width: '100%' }}>
          <WordTrail name={'project_h2'} idx={idx} paragraph={name} />
        </div>

        <animated.div
          className='imageContainer'
          style={{ width: '100%', position: 'relative', ...opacAnim }}
        >
          {props.children}
        </animated.div>
      </div>
    </>
  );
}

const ErrorPage = () => {
  const [location, setLocation] = useLocation();
  const textOpacAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.molasses,
    delay: 500,
  });

  const buttonOpacAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.molasses,
    delay: 1000,
  });
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <MissingPage style={{ height: '15vh', width: 'auto', marginBlock: '2vh' }} />
      <animated.span
        className='regRobo'
        style={{ fontSize: '2vh', textAlign: 'center', ...textOpacAnim }}
      >
        Error 404
      </animated.span>
      <animated.div
        className='modern-button regRobo'
        onClick={() => setLocation('/')}
        style={{
          pointerEvents: 'all',
          cursor: 'pointer',
          paddingInline: '5vh',
          paddingBlock: '1.5vh',
          marginBlock: '2vh',
          borderRadius: '1vh',
          color: 'white',
          ...buttonOpacAnim,
        }}
      >
        Return to Home
      </animated.div>
    </div>
  );
};

export function HtmlWrapper() {
  const [nextClicked, setNextClicked] = useState(false);
  const [location, setLocation] = useLocation();
  const [trigger, setTrigger] = useState(false)

  const handleClick = (loc) => {
    if (!nextClicked) {
      setNextClicked((prev) => !prev);
      setLocation(loc)
      const timerId = setTimeout(() => {
        window.scrollTo(0,0)
        setNextClicked((prev) => !prev);
      }, 250);
      return () => {
        clearTimeout(timerId);
      };
    }
  };

  const transition = useTransition(location, {
    from: { transform: 'translate3d(0, 120vh, 0)', opacity: 0 },
    enter: { transform: 'translate3d(0, 0vh, 0)', opacity: 1 },
    leave: {
      transform:
        ['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location) 
          ? 'translate3d(0, 0vh, 0)'
          : 'translate3d(0, 120vh, 0)',
      opacity: 0,
    },
    config:
      ['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location) 
        ? config.stiff
        : config.default,
    delay:
      ['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location)
        ? 0
        : 150,
  });

  const cover = useTransition(trigger, {
    from: {
      transform: 'translate3d(0, 150vh, 0)'
    },
    enter: { 
      transform: trigger ? 'translate3d(0, 0vh, 0)' : 'translate3d(0, 150vh, 0)'
     },
    leave: {
      transform: 'translate3d(0, 150vh, 0)'
    },
    config: nextClicked ? config.default : config.slow,
    delay: nextClicked ? 0 : 150,
  });

  const opac_cover = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 0.5 },
    leave: { opacity: 0 },
    config: config.default,
    delay: ['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location) ? 0 : 350,
  });
  const previousValueRef = useRef(location);
  const [overflow, setOverflow] = useState('hidden')

  useEffect(() => {
    if (!['/projects', '/'].includes(location))
      setTrigger(true)
    else
      setTrigger(false)

    if (location !== previousValueRef.current) {
      if (['/', '/projects'].includes(previousValueRef.current) && ['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location)) {
        previousValueRef.current = location
        const timerId = setTimeout(() => {
          setOverflow('visible')
        }, 500)
        return () => {
          clearTimeout(timerId)
        }
      } else if (['/', '/projects'].includes(location)) 
        setOverflow('hidden')
      previousValueRef.current = location
    }
  }, [location]);

  return (
    <>
      <div style={{
        height: '100%', 
        width:'100%', 
        position:'absolute', 
        zIndex: 10, 
        pointerEvents: ['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location) ? 'all' : 'none', 
        overflow: overflow}}>
        {opac_cover(({ ...props }, location) => (
          <>
            {['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location) ? (
              <animated.div
                style={{
                  position: 'absolute',
                  height: '150%',
                  top: '0px',
                  width: '100%',
                  left: '0px',
                  backgroundColor: '#827467',
                  ...props,
                }}
              />
            ) : null}
          </>
        ))}
        {cover(({ ...props }, trigger) => (
          <>
              <animated.div
                className='home'
                style={{ 
                position: 'fixed', 
                height: '150%', 
                width: '100%', 
                left: '0px', 
                ...props }}
              />
          </>
        ))}
        {transition(({ ...props }, location) => (
          <animated.div className='project_page_container' style={{ ...props }}>
            <Switch location={location}>
              <Route path='/' />
              <Route path='/projects' />
              <Route path='/optimization'>
                <Suspense>
                  <Optimization proj_idx={location === '/optimization'} next={handleClick} />
                </Suspense>
              </Route>

              <Route path='/emacrossover'>
                <Suspense>
                  <EMACrossover
                    proj_idx={location === '/emacrossover' && !nextClicked}
                    next={handleClick}
                  />
                </Suspense>
              </Route>

              <Route path='/solcity'>
                <Suspense>
                  <SolCity proj_idx={location === '/solcity' && !nextClicked} next={handleClick} />
                </Suspense>
              </Route>

              <Route path='/tasklogger'>
                <Suspense>
                  <TaskLogger proj_idx={location === '/tasklogger' && !nextClicked} next={handleClick} />
                </Suspense>
              </Route>

              <Route path='/lain'>
                <Suspense>
                  <Lain proj_idx={location === '/lain' && !nextClicked} next={handleClick} />
                </Suspense>
              </Route>

              <Route>
                <Suspense>
                  <ErrorPage />
                </Suspense>
              </Route>
            </Switch>
          </animated.div>
        ))}
      </div>
    </>
  );
}
