/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber';
import {
  useMask,
  Text as DreiText,
  GradientTexture,
  ScrollControls,
  Scroll,
  Preload,
  useScroll,
  useCursor,
} from '@react-three/drei';
import { useLocation } from 'wouter';
import { TextureLoader } from 'three';
import { a, config, useSpring, easings } from '@react-spring/three';
import { a as animated } from 'react-spring';
import Petals from '../R3F/Petals';
import { HideScrollbar, ResetScrollTop, SetHomeHeight, useWindowWidth } from '../Misc/Utilities';
import { proxy, useSnapshot } from 'valtio';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import { ReactComponent as ErrorSVG } from '../../Files/error_triangle.svg';
import HomePageText, { HtmlHomepageText, IntroText } from '../Misc/HomePageText';
import Heart from '../R3F/Heart';
import Tree from '../R3F/Tree';
import HandReach from '../R3F/HandReach';
import Cloud from '../../Files/cloud.png';
import Grass from '../../Files/grass.jpg';
import SlimeMask from '../R3F/SlimeMask';
import { ProjectPage } from './ProjectMenuPage';
import '../../index.css';

const state = proxy({ position: 0, display: 'flex', contactClick: false });
const containerState = proxy({ hover: false, click: false, tab: false });
const emailState = proxy({ open: false, sent: false, hide: false, error: false });
const ScrollIntoViewState = proxy({ down: false });

function Email() {
  const BUILD_ENV = process.env.REACT_APP_API_LINK;
  const emailSnap = useSnapshot(emailState);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const subject = 'Contact Message';
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [sent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (nameRef.current.value === '') setNameError('Uh oh... This is empty!');

    if (!EmailValidator.validate(emailRef.current.value))
      setEmailError('Please enter a valid email address.');

    if (messageRef.current.value === '') setMessageError('Uh oh... This is empty!');

    if (
      EmailValidator.validate(emailRef.current.value) &&
      messageRef.current.value &&
      nameRef.current.value
    ) {
      setIsLoading(true);
      setNameError('');
      setMessageError('');
      setEmailError('');

      axios
        .post(BUILD_ENV, {
          subject,
          text: `Name: ${nameRef.current.value}\nEmail: ${emailRef.current.value}\nMessage: ${messageRef.current.value}`,
        })
        .then((response) => {
          if (response.data === 'Success') {
            setSent(true);
            nameRef.current.value = null;
            emailRef.current.value = null;
            messageRef.current.value = null;

            setTimeout(() => {
              setSent(false);
              emailState.open = false;
              setIsLoading(false);
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          emailState.open = false;
          emailState.error = true;
        });
    }
  };

  const messageSentAnim = useSpring({
    opacity: sent ? 1 : 0,
    marginTop: sent ? 0 : -25,
    config: config.slow,
    delay: sent ? 500 : 0,
  });

  const errorMessageAnim = useSpring({
    opacity: emailState.error ? 1 : 0,
    marginTop: emailState.error ? 0 : -25,
    config: config.slow,
    delay: emailState.error ? 500 : 0,
  });

  const ContainerSlide = useSpring({
    opacity: emailState.open ? (sent ? 0 : 1) : 0,
    marginTop: emailState.open ? (sent ? -25 : 0) : -25,
    config: config.slow,
    delay: sent ? 100 : 0,
  });

  const opacCoverAnim = useSpring({
    opacity: emailState.open || emailState.error ? (sent ? 0 : 0.5) : 0,
    config: config.slow,
  });

  useEffect(() => {
    if (!emailSnap.open) {
      const timerId = setTimeout(() => {
        nameRef.current.value = null;
        emailRef.current.value = null;
        messageRef.current.value = null;
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [emailSnap.open]);
  return (
    <>
      {emailState.open ?
        <>
          <animated.div
            style={{
              position: 'fixed',
              zIndex: 8,
              width: '100%',
              height: '100%',
              backgroundColor: '#82746799',
              visibility: emailState.open || emailSnap.error ? 'visible' : 'hidden',
              ...opacCoverAnim,
            }}
          />
          <div
            className='emailBackdrop'
            style={{
              position: 'fixed',
              zIndex: 8,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              visibility: emailState.open || emailSnap.error ? 'visible' : 'hidden',
            }}
          />
          {emailState.open ?
            <animated.span className='message-sent' style={messageSentAnim}>
              Message delivered.
            </animated.span>
            : null}
          <animated.div
            className='error-message-container'
            style={{ visibility: emailState.error ? 'visible' : 'hidden', ...errorMessageAnim }}
          >
            <ErrorSVG style={{ height: '10vh' }} />
            <span style={{ textAlign: 'center', marginBlock: '0 2vh' }}>
              There was an error.
              <br />
              Please try again later.
            </span>
            <div
              className='error-close-button'
              onClick={() => {
                emailState.error = false;
              }}
            >
              Close
            </div>
          </animated.div>
          <animated.div
            className='emailFormContainer'
            style={{ zIndex: 9, visibility: emailState.open ? 'visible' : 'hidden', ...ContainerSlide }}
          >
            <div
              className='close-button'
              onClick={() => {
                emailState.open = false;
              }}
            />
            <form onSubmit={handleSubmit} style={{ height: '90%', width: '90%' }}>
              <animated.div
                className='top-input'
                style={{ border: nameError ? '1px solid #eb1b1b50' : null }}
              >
                <input type='text' placeholder='Your name' style={{ width: '95%' }} ref={nameRef} />
              </animated.div>
              <span className='roboReg email-error'>{nameError ? nameError : null}</span>
              <animated.div
                className='top-input'
                style={{ border: emailError ? '1px solid #eb1b1b50' : null }}
              >
                <input
                  type='text'
                  placeholder='Enter your email here'
                  style={{ width: '95%' }}
                  ref={emailRef}
                />
              </animated.div>
              <span className='roboReg email-error'>{emailError ? emailError : null}</span>
              <animated.div
                className='bottom-textarea'
                style={{ border: messageError ? '1px solid #eb1b1b50' : null }}
              >
                <textarea type='text' placeholder='Message' style={{ width: '95%' }} ref={messageRef} />
              </animated.div>
              <span className='roboReg email-error'>{messageError ? messageError : null}</span>
              <button className='modern-button' type='submit' disabled={isLoading}>
                {isLoading ? 'Wait' : 'Send'}
              </button>
            </form>
          </animated.div>
        </> :
        null
      }
    </>
  );
}

function MaskedContent({ width, ...props }) {
  const stencil3 = useMask(3, false);
  const stencil2 = useMask(2, false);
  const stencil10 = useMask(10, false);
  const cloud_img = useLoader(TextureLoader, Cloud);
  const grass_img = useLoader(TextureLoader, Grass);

  return (
    <group {...props}>
      <a.group scale={0.65} position={width ? [0, -17.2, -0.35] : [0, -17.3, -0.35]}>
        <mesh position={[-0.65, -1.5, -0.75]} scale={[1, 0.5, 1]}>
          <planeBufferGeometry args={[1.25, 1.25]} />
          <meshBasicMaterial map={cloud_img} transparent opacity={0.25} {...stencil2} />
        </mesh>

        <mesh position={[1.5, 0.15, 0.5]} scale={[1, 0.65, 1]}>
          <planeBufferGeometry args={[2.75, 2.3]} />
          <meshBasicMaterial map={cloud_img} transparent opacity={0.225} {...stencil2} />
        </mesh>

        <mesh position={[-0, -1, -1]}>
          <planeBufferGeometry args={[8, 8]} />
          <meshBasicMaterial map={grass_img} {...stencil2} />
        </mesh>

        {/* birds */}
        <group position={[1, -0.5, 0]}>
          <mesh scale={[1, 0.25, 1]}>
            <DreiText
              fontSize={0.25}
              color={'black'}
              position={[0, -3.25, 0]}
              rotation={[0, 0, 0.1]}
            >
              v
              <meshBasicMaterial transparent opacity={0.4} {...stencil2} />
            </DreiText>
          </mesh>

          <mesh scale={[1, 0.35, 1]}>
            <DreiText
              fontSize={0.25}
              color={'black'}
              position={[-0.55, -2.75, 0]}
              rotation={[0, 0, 0.1]}
            >
              v
              <meshBasicMaterial transparent opacity={0.5} {...stencil2} />
            </DreiText>
          </mesh>

          <mesh scale={[1, 0.35, 0.25]}>
            <DreiText
              fontSize={0.25}
              color={'black'}
              position={[-0.25, -2.85, 0]}
              rotation={[0, 0, 0.1]}
            >
              v
              <meshBasicMaterial transparent opacity={0.4} {...stencil2} />
            </DreiText>
          </mesh>
        </group>

        <mesh position={[-0, -1, -0.98]}>
          <planeBufferGeometry args={[8, 8]} />
          <meshBasicMaterial transparent opacity={0.15} {...stencil2}>
            <GradientTexture
              stops={[0.35, 0.625, 0.65]}
              colors={['#00D1FF', '#75C5FF', '#1FDD6B']}
              size={10}
            />
          </meshBasicMaterial>
        </mesh>
        <mesh position={[-0, -1, -0.99]}>
          <planeBufferGeometry args={[8, 8]} />
          <meshBasicMaterial transparent opacity={0.525} {...stencil2}>
            <GradientTexture
              stops={[0.2, 0.3, 0.9]}
              colors={['#8BC8F4', '#F4E5C7', '#D19F82']}
              size={1000}
            />
          </meshBasicMaterial>
        </mesh>
      </a.group>

      <mesh position={[0, 0, -1]}>
        <planeBufferGeometry args={[5, 5]} />
        <meshBasicMaterial color={'white'} transparent opacity={0.1} {...stencil3} />
      </mesh>

      <mesh position={[0, 0, -25]}>
        <planeBufferGeometry args={[115, 80]} />
        <meshBasicMaterial {...stencil10} color={'white'} />
      </mesh>
    </group>
  );
}

function TopTabbar({ progress, isProjOpen, ...props }) {
  const ScrollIntoView = useSnapshot(ScrollIntoViewState);
  const [location, setLocation] = useLocation();
  const windowWidth = useWindowWidth();
  const [hover, setHover] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [logo, setLogo] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleClick = (loc) => {
    if (!clicked) {
      setClicked((prev) => !prev);
      setLocation(loc);
      const timerId = setTimeout(() => {
        setClicked((prev) => !prev);
      }, 500);
    }
  };

  useEffect(() => {
    if ((progress === 100 && !loaded && !isProjOpen) || location === '/') {
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    } else if ((loaded && isProjOpen) || !['/projects', '/'].includes(location)) {
      setLoaded(false);
    }
    if (progress === 100) {
      setTimeout(() => {
        setLogo(true);
      }, 500);
    }
  }, [isProjOpen, location, loaded, progress]);

  const menuProps = useSpring({
    top: 10,
    alignItems: 'center',
    opacity: loaded ? 1 : 0,
    width: loaded ? 320 : 0,
    config: config.slow,
  });

  const logoProps = useSpring({
    opacity: logo ? 1 : 0,
    width: 100,
    config: config.slow,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 20,
    top: 10,
  });

  const homeProps = useSpring({
    opacity: loaded ? 1 : 0,
    color:
      location === '/' && !ScrollIntoView.tab
        ? '#534841'
        : hover === 'home'
          ? '#534841f2'
          : '#534841b2',
    config: config.default,
    marginRight: '30px',
  });

  const projProps = useSpring({
    opacity: loaded ? 1 : 0,
    color: ['/projects', '/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(
      location,
    )
      ? '#534841'
      : hover === 'projects'
        ? '#534841f2'
        : '#534841b2',
    config: config.default,
  });

  const contProps = useSpring({
    opacity: loaded ? 1 : 0,
    color:
      ScrollIntoView.tab && location === '/'
        ? '#534841'
        : hover === 'contact'
          ? '#534841f2'
          : '#534841b2',
    config: config.default,
    marginLeft: '30px',
  });

  const tabsProps = useSpring({
    opacity: loaded ? 0.75 : 0,
    position: 'absolute',
    left: loaded
      ? ['/projects', '/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(
        location,
      )
        ? 94.5
        : ScrollIntoViewState.tab
          ? 202
          : 4
      : 0, //4
    height: 54.5,
    width: loaded
      ? ['/projects', '/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(
        location,
      )
        ? 115
        : ScrollIntoView.tab
          ? 115
          : 98
      : 0, //100
    backgroundColor: '#FFFDFB',
    borderRadius: [
      '/projects',
      '/optimization',
      '/emacrossover',
      '/tasklogger',
      '/lain',
      '/solcity',
    ].includes(location)
      ? 25
      : 200,
    config: config.slow,
    pointerEvents: 'none',
  });

  return (
    <>
      <animated.div style={logoProps}>
        {windowWidth ?
          <div className='boldRobo' style={{ fontSize: '1.1em', opacity: 0.75 }}>
            Sam Pham
          </div>
          : null}
      </animated.div>
      <animated.div className={'overlayContainer'} style={menuProps}>
        <animated.h2
          className={'overlay'}
          style={homeProps}
          onMouseDownCapture={() => handleClick('/')}
          onMouseEnter={() => setHover('home')}
          onMouseLeave={() => setHover('')}
        >
          Home
        </animated.h2>
        <animated.h2
          className={'overlay'}
          style={projProps}
          onMouseDownCapture={() => handleClick('/projects')}
          onMouseEnter={() => setHover('projects')}
          onMouseLeave={() => setHover('')}
        >
          Projects
        </animated.h2>
        <animated.h2
          className={'overlay'}
          style={contProps}
          onClick={props.contactClick}
          onMouseEnter={() => setHover('contact')}
          onMouseLeave={() => setHover('')}
        >
          Contact
        </animated.h2>
        <animated.div className={'tabs'} style={tabsProps} />
      </animated.div>
    </>
  );
}

function BottomTabbar({ progress, isProjOpen, setProjectsOpened, ...props }) {
  const ScrollIntoView = useSnapshot(ScrollIntoViewState);
  const [location, setLocation] = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [hover, setHover] = useState('');

  const handleClose = () => {
    if (isProjOpen) {
      setProjectsOpened(false);
      setLocation('/projects');
    }
  };

  if (progress === 100 && !loaded && isProjOpen) {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  } else if (loaded && !isProjOpen) {
    setLoaded(false);
  }

  const menuProps = useSpring({
    bottom: 10,
    alignItems: 'center',
    opacity: loaded ? 1 : 0,
    width: loaded ? 320 : 0,
    config: config.slow,
  });

  const homeProps = useSpring({
    opacity: loaded ? 1 : 0,
    color:
      location === '/' && !ScrollIntoView.tab
        ? '#534841'
        : hover === 'home'
          ? '#534841f2'
          : '#534841b2',
    config: config.default,
    marginRight: '30px',
  });

  const projProps = useSpring({
    opacity: loaded ? 1 : 0,
    color: ['/projects', '/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(
      location,
    )
      ? '#534841'
      : hover === 'projects'
        ? '#534841f2'
        : '#534841b2',
    config: config.default,
  });

  const contProps = useSpring({
    opacity: loaded ? 1 : 0,
    color:
      ScrollIntoView.tab && location === '/'
        ? '#534841'
        : hover === 'contact'
          ? '#534841f2'
          : '#534841b2',
    config: config.default,
    marginLeft: '30px',
  });

  const tabsProps = useSpring({
    opacity: loaded ? 0.75 : 0,
    position: 'absolute',
    left: loaded
      ? ['/projects', '/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(
        location,
      )
        ? 94.5
        : ScrollIntoView.tab
          ? 202
          : 4
      : 0,
    height: 54.5,
    width: loaded
      ? ['/projects', '/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(
        location,
      )
        ? 115
        : ScrollIntoView.tab
          ? 115
          : 98
      : 0,
    backgroundColor: '#FFFDFB',
    borderRadius: [
      '/projects',
      '/optimization',
      '/emacrossover',
      '/tasklogger',
      '/lain',
      '/solcity',
    ].includes(location)
      ? 25
      : 200,
    config: config.slow,
    pointerEvents: 'none',
  });

  const XProps = useSpring({
    opacity: loaded ? 0.35 : 0,
    top: 20,
    config: loaded ? config.molasses : config.stiff,
  });

  const { rotateZ } = useSpring({
    from: {
      rotateZ: -180,
    },
    to: {
      rotateZ: loaded ? 0 : -180,
    },
    config: {
      tension: 280,
      friction: 90,
      easings: easings.easeInOutBounce,
    },
  });

  return (
    <>
      <animated.div
        onMouseDownCapture={() => handleClose()}
        style={{
          height: '32.5px',
          width: '32.5px',
          position: 'absolute',
          cursor: 'pointer',
          userSelect: 'none',
          right: '5vw',
          pointerEvents: loaded ? 'all' : 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          transform: rotateZ.to((z) => `rotateZ(${z}deg)`),
          ...XProps,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div
            style={{
              width: '4px',
              height: '10px',
              backgroundColor: '#15110F',
              transform: 'rotateZ(-45deg)',
              mixBlendMode: 'difference',
            }}
          />
          <div
            style={{
              width: '4px',
              height: '10px',
              backgroundColor: '#15110F',
              transform: 'rotateZ(45deg)',
              mixBlendMode: 'exclusion',
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div
            style={{
              width: '4px',
              height: '10px',
              backgroundColor: '#15110F',
              transform: 'rotateZ(45deg)',
              mixBlendMode: 'exclusion',
            }}
          />
          <div
            style={{
              width: '4px',
              height: '10px',
              backgroundColor: '#15110F',
              transform: 'rotateZ(-45deg)',
              mixBlendMode: 'exclusion',
            }}
          />
        </div>
      </animated.div>
      {loaded ? (
        <>
          <animated.div className={'overlayContainer'} style={{ userSelect: 'none', ...menuProps }}>
            <animated.h2
              className={'overlay'}
              style={homeProps}
              onMouseDownCapture={() => setLocation('/')}
              onMouseEnter={() => setHover('home')}
              onMouseLeave={() => setHover('')}
            >
              {' '}
              Home{' '}
            </animated.h2>
            <animated.h2
              className={'overlay'}
              style={projProps}
              onMouseDownCapture={() => setLocation('/projects')}
              onMouseEnter={() => setHover('projects')}
              onMouseLeave={() => setHover('')}
            >
              {' '}
              Projects
            </animated.h2>
            <animated.h2
              className={'overlay'}
              style={contProps}
              onClick={props.contactClick}
              onMouseEnter={() => setHover('contact')}
              onMouseLeave={() => setHover('')}
            >
              {' '}
              Contact{' '}
            </animated.h2>
            <animated.div className={'tabs'} style={tabsProps} />
          </animated.div>
        </>
      ) : null}
    </>
  );
}

function LoadingPage() {
  const ScaleAnim = useSpring({
    loop: true,
    from: { scale: 1 },
    to: { scale: 2 },
    config: config.slow,
  });

  const OpacAnim = useSpring({
    loop: true,
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: config.slow,
  });

  const ScaleAnim2 = useSpring({
    loop: true,
    delay: 100,
    from: { scale: 1 },
    to: { scale: 2 },
    config: config.slow,
  });

  const OpacAnim2 = useSpring({
    loop: true,
    delay: 100,
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: config.slow,
  });

  const ScaleAnim3 = useSpring({
    loop: true,
    delay: 200,
    from: { scale: 1 },
    to: { scale: 2 },
    config: config.slow,
  });

  const OpacAnim3 = useSpring({
    loop: true,
    delay: 200,
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: config.slow,
  });

  return (
    <group position={[0, 0, 0.5]} scale={0.45}>
      <a.group position={[0.85, 0, -0.1]}>
        <mesh>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <meshBasicMaterial color={'#C78E57'} transparent opacity={0.75} />
        </mesh>

        <a.mesh {...ScaleAnim3}>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <a.meshBasicMaterial color={'#C78E57'} transparent {...OpacAnim3} />
        </a.mesh>
      </a.group>

      <a.group position={[0, 0, -0.1]}>
        <mesh>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <meshBasicMaterial color={'#1FDD6B'} transparent opacity={0.75} />
        </mesh>

        <a.mesh {...ScaleAnim2}>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <a.meshBasicMaterial color={'#1FDD6B'} transparent {...OpacAnim2} />
        </a.mesh>
      </a.group>

      <a.group position={[-0.85, 0, -0.1]}>
        <mesh>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <meshBasicMaterial color={'#4AC1F2'} transparent opacity={0.75} />
        </mesh>

        <a.mesh {...ScaleAnim}>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <a.meshBasicMaterial color={'#4AC1F2'} transparent {...OpacAnim} />
        </a.mesh>
      </a.group>
    </group>
  );
}

function HeartClick({ width, openEmail }) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const snap = useSnapshot(containerState);
  const ScrollIntoView = useSnapshot(ScrollIntoViewState);
  const emailSnap = useSnapshot(emailState);
  const data = useScroll();
  const [location, setLocation] = useLocation();
  const [formVisible, setFormVisible] = useState();
  const [hover, setHover] = useState(false);
  const [click, setClick] = useState(false);

  useCursor(hover, 'pointer');

  const changePage = () => {
    const timerId = setTimeout(() => {
      setTimeout(() => {
        setClick(false);
      }, 250);
      setLocation('/projects');
    }, isMobile ? 1000 : 750);
    return () => {
      clearTimeout(timerId);
    };
  };

  const handleClick = () => {
    if (width && data.offset > 0.6 && !emailSnap.open) {
      setClick(true);
      setHover(false);
      changePage();
    } else if (!width && data.offset > 0.85 && !emailSnap.open) {
      setClick(true);
      setHover(false);
      changePage();
    }
  };

  const handleHover = () => {
    if (emailSnap.error) {
      setHover(false);
    } else if (width && data.offset > 0.6 && !emailSnap.open) {
      setHover(true);
    } else if (!width && data.offset > 0.85 && !emailSnap.open) {
      setHover(true);
    }
  };

  useFrame(() => {
    const f = data.visible(0.85, 0.15);
    if (f !== formVisible) {
      setFormVisible(f);
    }
  });

  useEffect(() => {
    if (!formVisible && emailSnap.open && (isMobile || !width)) {
      emailState.hide = true;
      emailState.error = false;
    } else {
      emailState.hide = false;
    }

    if (!formVisible && emailSnap.error && (isMobile || !width)) {
      emailState.error = false;
    }
  }, [formVisible, isMobile, width, emailSnap]);

  return (
    <>
      <HomePageText
        heartClicked={click}
        contactClicked={ScrollIntoViewState.down}
        heartHover={hover}
        width={width}
        emailOpened={emailSnap.open || emailSnap.error}
        openEmail={openEmail}
      />
      <mesh
        position={
          location === '/' ? (width ? [-2.45, -12.25, -6.35] : [-2.375, -12.2, -6.35]) : [0, -20, 0]
        }
        onPointerOver={() => handleHover()}
        onPointerOut={() => setHover(false)}
        onClick={() => handleClick()}
      >
        <planeBufferGeometry args={[2.15, 1.65]} />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>
      <mesh
        position={location === '/' ? [0.8, -12.05, -9] : [0, -20, 0]}
        onPointerOver={() => handleHover()}
        onPointerOut={() => setHover(false)}
        onClick={() => handleClick()}
      >
        <circleBufferGeometry args={[1.8, 64]} />
        <meshBasicMaterial transparent opacity={0.0} />
      </mesh>

      <Petals projectClick={click} width={width} />
      <Petals projectClick={click} position={[0, -2, -2]} width={width} />
    </>
  );
}

const ContactClick = ({ loc }) => {
  const ScrollIntoView = useSnapshot(ScrollIntoViewState);
  const data = useScroll();
  const [tab, setTab] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    const scrollDown = () => {
      data.el.scrollTop = 1;
      data.el.scrollTop = data.el.scrollHeight - data.el.clientHeight;
      if (atBottom) {
        ScrollIntoViewState.down = false;
      } else if (!atBottom && ScrollIntoViewState.down) {
        setTimeout(scrollDown, 250);
      }
    };

    if (ScrollIntoViewState.down && loc)
      scrollDown();

    if (tab || ScrollIntoViewState.down)
      ScrollIntoViewState.tab = true;
    else
      ScrollIntoViewState.tab = false;

    return () => {
      clearTimeout(scrollDown);
    };
  }, [data.el, data.offset, ScrollIntoView.down, atBottom, loc, tab]);

  useFrame(() => {
    const t = data.visible(0.85, 1);
    const bt = data.visible(0.99, 1);
    if (bt !== atBottom)
      setAtBottom(bt);

    if (t !== tab) {
      setTab(t);
      console.log('tabbed')
    }
  });
};

function Home({ loaded }) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const stencil9 = useMask(9, true);
  const containerSnapState = useSnapshot(containerState);
  const snap = useSnapshot(state);
  const ScrollIntoView = useSnapshot(ScrollIntoViewState);
  const emailSnap = useSnapshot(emailState);

  const [location, setLocation] = useLocation();
  const [currLoc, setCurrLoc] = useState(location);
  const windowWidth = useWindowWidth();
  const { viewport } = useThree();
  const previousValueRef = useRef(location);
  const [flip, setFlip] = useState(false);
  const [reduce, setReduce] = useState(false);

  const HomepageCoverOpac = useSpring({
    from: { opacity: 1 },
    to: { opacity: location === '/' ? 0 : 1 },
    config: location === '/' ? config.molasses : config.default,
    delay: location === '/' ? 250 : 0,
  });

  const HomepageCoverOpac2 = useSpring({
    from: { opacity: 0 },
    to: { opacity: location !== '/' ? 1 : 0 },
    config: config.default,
  });

  const AboutCoverReturnSlide2 = useSpring({
    from: { position: [0, -10, 0] },
    to: { position: location === '/' ? [0, -10, -10] : [0, -10, 0] },
    config: location === '/' ? config.slow : config.stiff,
    delay: location === '/' ? 250 : 0,
  });

  const moveOutAnim = useSpring({
    from: { position: [0, 0, 0] },
    to: { position: location !== '/' ? [0, -0.5, -0.5] : [0, 0, 0] },
    config: config.molasses,
  });

  const openEmail = () => {
    if (location === '/') {
      emailState.open = true;
    }
  };

  const colorReducer = () => {
    if (location === '/') {
      setReduce((prev) => !prev);
    }
  };

  useEffect(() => {
    if (location !== previousValueRef && location === '/') {
      const timerId = setTimeout(() => {
        previousValueRef.current = location;
        setFlip((prev) => !prev);
        setCurrLoc(location);
      }, 250);
      return () => {
        clearTimeout(timerId);
      };
    } else previousValueRef.current = location;

    if (location !== '/') emailState.open = false;

    if (location !== currLoc && location !== '/') {
      const timerId = setTimeout(() => {
        setCurrLoc(location);
      }, 150);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [currLoc, setLocation, location, windowWidth]);

  return (
    <a.group>
      <Preload />
      <ScrollControls
        pages={flip ? 3 : 3.001}
        damping={isMobile ? 0 : 0.2}
        distance={isMobile ? 0.35 : 0.55}
      >
        <ResetScrollTop send={currLoc === location && !ScrollIntoView.down} />
        <ContactClick send={ScrollIntoView.down} loc={location === '/' && currLoc === location} />
        <SetHomeHeight
          loc={currLoc === '/'}
          toTop={currLoc !== location}
          heartClicked={containerState.click}
          reset={currLoc === location}
        />
        <HideScrollbar hide={location !== '/'} />
        <IntroText width={windowWidth} visible={currLoc === '/'} />

        <Scroll>
          <Suspense fallback={loaded ? <LoadingPage /> : null}>
            <SlimeMask position={[0, -0.5, 0]} width={windowWidth} visible={currLoc === '/'} />
            <a.group visible={isMobile ? currLoc === '/' ? location === '/' : currLoc === '/' : currLoc === '/'} {...moveOutAnim}>

              <group scale={windowWidth ? [1, 1, 1] : [0.75, 0.725, 0.725]}>
                <Suspense fallback={null}>
                  <Tree scale={0.95} position={[-2.2, -viewport.height, -5.5]} width={windowWidth} />
                  <Heart scale={0.95} position={[-2.4, -11.85, -5]} />
                </Suspense>
                <HeartClick width={windowWidth} openEmail={openEmail} />
              </group>

              <group scale={windowWidth ? [1, 1, 1] : [0.7, 0.7, 0.7]}>
                <Suspense fallback={null}>
                  <Petals projectClick={containerSnapState.click} width={windowWidth} />
                  <Petals
                    projectClick={containerSnapState.click}
                    position={[0, -2, -2]}
                    width={windowWidth}
                  />
                </Suspense>
                <MaskedContent width={windowWidth} />
                <HandReach
                  scale={windowWidth ? 1 : 1.175}
                  position={windowWidth ? [0, -21, -0] : [0, -21.5, -0]}
                  heartClicked={containerSnapState.click}
                />

                {/* divider */}
                <mesh
                  position={windowWidth ? [-0, -19.9, -1] : [-0, -19.9, -1]}
                  rotation={[0, 0, Math.PI / 2]}
                >
                  <planeBufferGeometry args={[0.01, 12.5]} />
                  <meshBasicMaterial transparent opacity={0.15}>
                    <GradientTexture
                      stops={[0.025, 0.5, 0.975]}
                      colors={['transparent', '#4D2B03', 'transparent']}
                      size={1000}
                    />
                  </meshBasicMaterial>
                </mesh>
              </group>
            </a.group>

            <group visible={currLoc === '/'}>
              <a.mesh {...AboutCoverReturnSlide2}>
                <planeBufferGeometry args={[70, 70]} />
                <a.meshBasicMaterial
                  color={'#FCEED3'}
                  transparent
                  {...HomepageCoverOpac}
                  {...stencil9}
                />
              </a.mesh>
              <a.mesh position={[0, 0, 0]} visible={location === '/projects'}>
                <planeBufferGeometry args={[70, 70]} />
                <a.meshBasicMaterial color={'#FCEED3'} transparent {...HomepageCoverOpac2} />
              </a.mesh>
            </group>
          </Suspense>
        </Scroll>

        <Scroll html style={{ width: '100%' }}>
          {currLoc === '/' ? (
            <>
              <HtmlHomepageText
                width={windowWidth}
                heartClicked={containerSnapState.click}
                contactClicked={ScrollIntoView.down}
                colorReducer={colorReducer}
                reduce={reduce}
              />
            </>
          ) : null}
        </Scroll>
      </ScrollControls>
    </a.group>
  );
}

function Overlay({ progress, isProjOpen, setProjectsOpened }) {
  const ScrollIntoView = useSnapshot(ScrollIntoViewState);
  const [location, setLocation] = useLocation();
  const [currLoc, setCurrLoc] = useState();

  const contactClick = () => {
    if (location === currLoc) {
      ScrollIntoViewState.tab = true;
      ScrollIntoViewState.down = true;
      if (location !== '/') setLocation('/');
    }
  };

  useEffect(() => {
    if (location !== currLoc) {
      const timerId = setTimeout(() => {
        setCurrLoc(location);
      }, 500);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [currLoc, location]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 12,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <TopTabbar progress={progress} contactClick={() => contactClick()} isProjOpen={isProjOpen} />
      <BottomTabbar
        progress={progress}
        contactClick={() => contactClick()}
        isProjOpen={isProjOpen}
        setProjectsOpened={setProjectsOpened}
      />
    </div>
  );
}

export function CanvasContainer(props) {
  const [isProjOpen, setOpen] = useState(false);

  const setProjectsOpened = (bool) => {
    setOpen(bool);
  };

  return (
    <>
      <Overlay
        progress={props.progress}
        isProjOpen={isProjOpen}
        setProjectsOpened={setProjectsOpened}
      />
      <Email />
      <div className={'home'} style={{ width: 'inherit', position: 'fixed', zIndex: 1 }}>
        <Suspense fallback={null}>
          <Canvas
            dpr={Math.max(window.devicePixelRatio, 2)}
            camera={{ position: [0, 0, 12.5], fov: 50, near: 0.1, far: 50 }}
            frameloop='always'
            resize={{ scroll: true, debounce: { scroll: 50, resize: 10 } }}
          >
            <Home loaded={props.progress === 100} />
            <ProjectPage
              setProjectsOpened={setProjectsOpened}
              isProjOpen={isProjOpen}
              heartClicked={containerState.click}
            />
          </Canvas>
        </Suspense>
      </div>
    </>
  );
}

export default CanvasContainer;
