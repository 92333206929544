import React, { useRef } from 'react';
import { useGLTF, useMask } from '@react-three/drei';

export default function EmaCrossover({ sect, ...props }) {
  const group = useRef();
  const { nodes } = useGLTF('models/emacrossover.glb');
  const stencil11 = useMask(11, false);
  const red = '#FF5252';
  const green = '#22AB94';

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Plane.geometry} position={[-1.55, 0, 1.12]} scale={[0.45, 1, 2.58]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane001.geometry} position={[-0.55, 0, -1.52]} scale={[0.45, 1, 0.72]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane002.geometry} position={[1.46, 0, -0.49]} scale={[0.45, 1, 0.37]}>
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh
        geometry={nodes.Plane003.geometry}
        position={[0.45, 0, -1.17]}
        rotation={[0, 0, -Math.PI]}
        scale={[-0.45, -1, -1.07]}
      >
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh geometry={nodes.Plane004.geometry} position={[4.45, 0, -0.79]} scale={[0.45, 1, 0.17]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh
        geometry={nodes.Plane005.geometry}
        position={[5.46, 0, -0.36]}
        rotation={[0, 0, -Math.PI]}
        scale={[-0.45, -1, -0.4]}
      >
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh
        geometry={nodes.Plane006.geometry}
        position={[3.45, 0, -0.55]}
        rotation={[0, 0, -Math.PI]}
        scale={[-0.45, -1, -0.07]}
      >
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane007.geometry} position={[2.45, 0, 0.1]} scale={[0.45, 1, 0.49]}>
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh geometry={nodes.Plane008.geometry} position={[10.45, 0, 1.43]} scale={[0.45, 1, 0.3]}>
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh
        geometry={nodes.Plane009.geometry}
        position={[11.45, 0, 1.07]}
        rotation={[0, 0, -Math.PI]}
        scale={[-0.45, -1, -0.31]}
      >
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane010.geometry} position={[13.46, 0, -1]} scale={[0.45, 1, 0.35]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane011.geometry} position={[12.45, 0, -0.08]} scale={[0.45, 1, 1.07]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane012.geometry} position={[8.45, 0, 2.4]} scale={[0.45, 1, 0.47]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane013.geometry} position={[9.46, 0, 1.79]} scale={[0.45, 1, 0.36]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane014.geometry} position={[7.45, 0, 1.3]} scale={[0.45, 1, 1.3]}>
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh geometry={nodes.Plane015.geometry} position={[6.45, 0, 0.05]} scale={[0.45, 1, 0.49]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane016.geometry} position={[-0.55, 0, -2.42]} scale={[0.02, 1, 0.72]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane017.geometry} position={[1.45, 0, -1.13]} scale={[0.02, 1, 0.72]}>
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh geometry={nodes.Plane018.geometry} position={[5.45, 0, -0.87]} scale={[0.02, 1, 0.23]}>
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh geometry={nodes.Plane019.geometry} position={[8.45, 0, 1.82]} scale={[0.02, 1, 0.26]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane020.geometry} position={[10.45, 0, 1.2]} scale={[0.02, 1, 0.26]}>
        <meshBasicMaterial {...stencil11} color={red} />
      </mesh>

      <mesh geometry={nodes.Plane011.geometry} position={[14.45, 0, -2]} scale={[0.45, 1, 1.07]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>

      <mesh geometry={nodes.Plane011.geometry} position={[14.45, 0, -3]} scale={[0.02, 1, 1.07]}>
        <meshBasicMaterial {...stencil11} color={green} />
      </mesh>
    </group>
  );
}

useGLTF.preload('models/emacrossover.glb');
