import React, { useRef } from 'react';
import { useGLTF, useMask } from '@react-three/drei';

export default function Ema({ loaded, width, ...props }) {
  const group = useRef();
  const { nodes } = useGLTF('models/ema.glb');
  const stencil11 = useMask(11, false);

  return (
    <group ref={group} dispose={null}>
      <group {...props}>
        <mesh geometry={nodes['50EMA'].geometry} rotation={[0, 0.1, 0]} position={[0.2, 0, -0.05]}>
          <meshBasicMaterial {...stencil11} color={'green'} transparent opacity={0.5} />
        </mesh>
        <mesh geometry={nodes['100EMA'].geometry} position={[0, 0, -0.1]} rotation={[0, 0.05, 0]}>
          <meshBasicMaterial {...stencil11} color={'red'} transparent opacity={0.5} />
        </mesh>
        <mesh
          geometry={nodes['200EMA'].geometry}
          position={[-0.02, 0, -0.15]}
          rotation={[0, -0.051, 0]}
        >
          <meshBasicMaterial {...stencil11} color={'#77C9EC'} transparent opacity={1} />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload('models/ema.glb');
