import React from 'react';
import { useMask, Edges } from '@react-three/drei';

export default function Optimization({ sect, loaded, width, ...props }) {
  const stencil10 = useMask(10, false);
  const col = 'beige';
  const opac = 0.25;

  return (
    <>
      <group {...props} dispose={null}>
        <group name='red bars' position={[0, 0.5, 0]}>
          <mesh position={[-0.625, 0.35, 0]}>
            <planeBufferGeometry args={[1.25, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.4, 0.3, 0]}>
            <planeBufferGeometry args={[0.8, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.25, 0.25, 0]}>
            <planeBufferGeometry args={[0.5, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[-0.375, 0.2, 0]}>
            <planeBufferGeometry args={[0.75, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[-0.3, 0.15, 0]}>
            <planeBufferGeometry args={[0.6, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.025, 0.1, 0]}>
            <planeBufferGeometry args={[0.05, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.05, 0.05, 0]}>
            <planeBufferGeometry args={[0.1, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.15, 0.0, 0]}>
            <planeBufferGeometry args={[0.3, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.075, -0.05, 0]}>
            <planeBufferGeometry args={[0.15, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.125, -0.1, 0]}>
            <planeBufferGeometry args={[0.25, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[-0.075, -0.15, 0]}>
            <planeBufferGeometry args={[0.15, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#FF5252'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
        </group>

        <group name='green bars' position={[0, 0.015, 0]}>
          <mesh position={[0.4, 0.3, 0]}>
            <planeBufferGeometry args={[0.8, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[0.5, 0.25, 0]}>
            <planeBufferGeometry args={[1, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[0.4, 0.2, 0]}>
            <planeBufferGeometry args={[0.8, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[0.1, 0.15, 0]}>
            <planeBufferGeometry args={[0.2, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[0.225, 0.1, 0]}>
            <planeBufferGeometry args={[0.45, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[0.175, 0.05, 0]}>
            <planeBufferGeometry args={[0.35, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>

          <mesh position={[0.5, 0, 0]}>
            <planeBufferGeometry args={[1, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[0.375, -0.05, 0]}>
            <planeBufferGeometry args={[0.75, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[0.125, -0.1, 0]}>
            <planeBufferGeometry args={[0.25, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
          <mesh position={[0.2, -0.15, 0]}>
            <planeBufferGeometry args={[0.4, 0.05]} />
            <meshBasicMaterial {...stencil10} color={'#22AB94'} />
            <Edges>
              <meshBasicMaterial {...stencil10} color={col} transparent opacity={opac} />
            </Edges>
          </mesh>
        </group>
      </group>
    </>
  );
}
