/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { a, config, useSpring, easings } from '@react-spring/three';
import React, { useEffect, useRef, useState } from 'react';
import {
  useMask,
  Text,
  Mask,
  GradientTexture,
  useCursor,
  ScrollControls,
  Scroll,
} from '@react-three/drei';
import { useLocation } from 'wouter';
import { useThree } from '@react-three/fiber';
import { proxy, useSnapshot } from 'valtio';

import Optimization from '../R3F/OptimizationMask';
import EmaCrossover from '../R3F/Emacrossover';
import Ema from '../R3F/Ema';
import SolcityBG from '../R3F/SolcityBG';
import Among from '../R3F/Among';
import Lain from '../R3F/LAIN';
import { HideScrollbar, SetDistance, SetHomeHeight, SetScrollTopToTop } from '../Misc/Utilities';
import { HtmlWrapper } from '../Misc/TextHelper';
import OctoCat from '../R3F/Octo';
import Arrow from '../R3F/Expand';

import '../../index.css';

function Title({ loaded, title, subtitle, idx, git, cover }) {
  const { viewport } = useThree();
  const z = 0.01;
  const min = Math.min(4.5, viewport.width / 2.275);

  const OpacAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded && !cover ? 0.95 : 0 },
    config: loaded ? config.molasses : config.stiff,
    delay: !cover ? (loaded ? (idx + 2) * 100 : 0) : 500,
  });

  return (
    <group position={[0.25, 0, 0.01]}>
      <Text
        position={[-min + 0.035, 0.295, 0]}
        position-z={z}
        anchorX='left'
        anchorY='middle'
        fontSize={0.15}
        lineHeight={0.75}
        letterSpacing={-0.075}
        color='#635448'
        maxWidth={viewport.width / 4}
        font='/Roboto-Regular.woff'
      >
        {!git ? `0${idx}` : null}
        <a.meshBasicMaterial transparent {...OpacAnim} />
      </Text>
      <Text
        position={[-min, -0.065, 0]}
        position-z={z}
        anchorX='left'
        anchorY='middle'
        fontSize={0.5}
        lineHeight={0.8}
        letterSpacing={-0.065}
        color='#110E0A'
        maxWidth={viewport.width}
        font='/Roboto-Regular.woff'
      >
        {title}
        <a.meshBasicMaterial transparent {...OpacAnim} />
      </Text>

      <Text
        position={[-min + 0.025, -0.385, 0]}
        position-z={z}
        anchorX='left'
        anchorY='middle'
        fontSize={0.18}
        lineHeight={0.75}
        letterSpacing={0.05}
        color='#2E271E'
        maxWidth={viewport.width}
        font='/Roboto-Regular.woff'
      >
        {subtitle}
        <a.meshBasicMaterial transparent {...OpacAnim} />
      </Text>
    </group>
  );
}

export function ProjectMenuHeader({ loaded, paragraph, cover, ...props }) {
  const { viewport, size } = useThree();
  const stencil1 = useMask(1, false);
  const maskWidth = Math.min(8.9, viewport.width / 1.15);
  const HeaderPosition = Math.min(4.5, viewport.width / 2.275);

  const OpacAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded && !cover ? 0.95 : 0 },
    config: config.molasses,
    delay: cover ? 500 : 0,
  });

  const TextSlide = useSpring({
    loop: true,
    from: { position: [8, 0, 0] },
    to: { position: [-3.92, 0, 0] },
    config: { duration: 30000 },
  });

  return (
    <group position={[0, 3.35, -0.05]} {...props}>
      <group position={[-HeaderPosition + 2.1, 0, 0]} scale={0.3}>
        <mesh position={[0.65, 0, -0]}>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <meshBasicMaterial color={'#C78E57'} transparent opacity={0.75} />
        </mesh>

        <mesh>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <meshBasicMaterial color={'#1FDD6B'} transparent opacity={0.75} />
        </mesh>

        <mesh position={[-0.65, 0, -0]}>
          <circleBufferGeometry args={[0.2, 64, 64]} />
          <meshBasicMaterial color={'#4AC1F2'} transparent opacity={0.75} />
        </mesh>
      </group>

      <Text
        position={[-HeaderPosition, 0, 0]}
        position-z={0}
        anchorX='left'
        anchorY='middle'
        fontSize={0.525}
        lineHeight={1}
        letterSpacing={-0.05}
        color='#17130F'
        font='/Roboto-Regular.woff'
      >
        Projects
        <a.meshBasicMaterial transparent {...OpacAnim} />
      </Text>

      <a.group {...TextSlide}>
        <Text
          position={[-0, -0.525, 0]}
          position-z={0}
          anchorX={'center'}
          anchorY='middle'
          fontSize={0.2}
          lineHeight={1}
          letterSpacing={0.025}
          color='#584B41'
          font='/Lato-Regular.woff'
        >
          {`NODE.JS\t\t\t\t\tRUST\t\t\t\t\tCRYPTO\t\t\t\t\tREACT NATIVE\t\t\t\t\tPYTHON\t\t\t\t\tREACT.JS\t\t\t\t\tDATA FRAMES\t\t\t\t\tWEBGL\t\t\t\t\tNODE.JS\t\t\t\t\tRUST\t\t\t\t\tCRYPTO\t\t\t\t\tREACT NATIVE\t\t\t\t\tPYTHON\t\t\t\t\tREACT.JS\t\t\t\t\tDATA FRAMES\t\t\t\t\tWEBGL\t\t\t\t\tNODE.JS`}
          <a.meshBasicMaterial transparent {...OpacAnim} {...stencil1} />
        </Text>
      </a.group>

      <Mask id={1} position={[0, -0.5, 0]}>
        <planeBufferGeometry args={[maskWidth, 0.5]} />
      </Mask>
    </group>
  );
}

function TileMask({ loc, id, idx, loaded, handleProjectClick, cover, git, ...props }) {
  const { viewport } = useThree();
  const [hover, setHover] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [location] = useLocation();
  useCursor(hover, 'pointer');

  const handleHover = (bool) => {
    if (location === '/projects') {
      setHover(bool);
    } else {
      setHover(false);
    }
  };

  const handleClick = () => {
    if (!clicked) {
      setClicked(true);
      handleProjectClick(loc);
      setHover(false);

      const timerId = setTimeout(() => {
        setClicked(false);
      }, 1000);
      return () => {
        clearTimeout(timerId);
      };
    }
  };

  const min = Math.min(8.5, viewport.width / 1.25);

  const rotationAnim = useSpring({
    from: { rotation: [Math.PI, 0, 0]},
    to: {
      rotation: loaded && !cover ? [0, 0, 0] : [Math.PI, 0, 0],
      
    },
    config: loaded ? config.molasses : config.stiff,
    delay: !cover ? (loaded ? (idx + 3) * 100 : 0) : 500,
  });
  return (
    <>
      <a.group position={[-min/2, -idx + 0.5, 0]} {...rotationAnim}>
        <Mask
          id={git ? 1000 : id}
          position={[min/2, -0.5, 0]}
          onClick={() => handleClick()}
          onPointerOver={() => handleHover(true)}
          onPointerOut={() => handleHover(false)}
        >
          <planeBufferGeometry args={[min, 1]} />
        </Mask>
        {/* <mesh position={[min/2, -0.5, 0]}>
          <planeBufferGeometry args={[min, 1]} />
          <meshBasicMaterial color={'red'} />
        </mesh> */}
      </a.group>
      <group position={[0, -idx, 0]}>{props.children}</group>
      <Arrow loaded={loaded} cover={cover} hover={hover} idx={idx} />
    </>
  );
}

function Dividers({ idx, loaded, cover }) {
  const DividerOpacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded && !cover ? 0.1 : 0 },
    config: loaded ? config.molasses : config.stiff,
    delay: !cover ? (loaded ? (idx + 3) * 100 : 0) : 500,
  });
  return (
    <>
      <mesh position={[0, -idx, 0.001]} rotation={[0, 0, Math.PI / 2]}>
        <planeBufferGeometry args={[0.0075, 8]} />
        <a.meshBasicMaterial transparent {...DividerOpacityTransition}>
          <GradientTexture
            stops={[0.025, 0.5, 0.975]}
            colors={['transparent', '#503A03', 'transparent']}
            size={1000}
          />
        </a.meshBasicMaterial>
      </mesh>
    </>
  );
}

function Menu({ menuVis, handleProjectClick, cover, loaded }) {
  const [location] = useLocation();
  const handleGithubClick = () => {
    if (location === '/projects') {
      window.open('https://github.com/Vetoah?tab=repositories', '_blank');
    }
  };
  const moveBackAnim = useSpring({
    from: { position: [0, 0, -0.03], scale: [1.05, 1.05, 1] },
    to: {
      position: cover ? [0, 0, -0.25] : [0, 0, -0.03],
      scale: loaded ? [1, 1, 1] : [1.05, 1.05, 1],
    },
    config: loaded && !cover ? config.molasses : config.slow,
    // delay: loaded && !cover ? 0 : cover ? 0 : 250
  });
  return (
    <a.group visible={menuVis} {...moveBackAnim}>
      <ProjectMenuHeader z={0} loaded={loaded} cover={cover} />
      <group position={[0, 1.55, 0]}>
        <group position={[0, 0.5, 0]}>
          <TileMask
            id={10}
            loc={'/optimization'}
            idx={0}
            loaded={loaded}
            cover={cover}
            handleProjectClick={handleProjectClick}
          >
            <Title
              title={'Optimization'}
              subtitle={`Python / ReactJS / Django`}
              idx={1}
              loaded={loaded}
              cover={cover}
              z={0}
            />
          </TileMask>
          <TileMask
            id={11}
            loc={'/emacrossover'}
            idx={1}
            loaded={loaded}
            cover={cover}
            handleProjectClick={handleProjectClick}
          >
            <Title
              title={'EMA Crossover'}
              subtitle={'Rust / API'}
              idx={2}
              loaded={loaded}
              cover={cover}
              z={0}
            />
          </TileMask>
          <TileMask
            id={12}
            loc={'/solcity'}
            idx={2}
            loaded={loaded}
            cover={cover}
            handleProjectClick={handleProjectClick}
          >
            <Title
              title={'SolCity'}
              subtitle={'Python / ReactJS / WebGL / NFT'}
              idx={3}
              loaded={loaded}
              cover={cover}
              z={0}
            />
          </TileMask>
          <TileMask
            id={13}
            loc={'/tasklogger'}
            idx={3}
            loaded={loaded}
            cover={cover}
            handleProjectClick={handleProjectClick}
          >
            <Title
              title={'Task Logger'}
              subtitle={'React Native / Frontend'}
              idx={4}
              loaded={loaded}
              cover={cover}
              z={0}
            />
          </TileMask>
          <TileMask
            id={14}
            loc={'/lain'}
            idx={4}
            loaded={loaded}
            cover={cover}
            handleProjectClick={handleProjectClick}
          >
            <Title
              title={'LAIN'}
              subtitle={'React Native / Frontend'}
              idx={5}
              loaded={loaded}
              cover={cover}
              z={0}
            />
          </TileMask>
          <TileMask
            git={true}
            id={15}
            idx={5}
            loaded={loaded}
            cover={cover}
            handleProjectClick={handleGithubClick}
          >
            <Title title={'Github'} idx={6} git={true} loaded={loaded} cover={cover} z={0} />
          </TileMask>
        </group>

        <group position={[0, 1, 0]}>
          <Dividers idx={0} loaded={loaded} cover={cover} />
          <Dividers idx={1} loaded={loaded} cover={cover} />
          <Dividers idx={2} loaded={loaded} cover={cover} />
          <Dividers idx={3} loaded={loaded} cover={cover} />
          <Dividers idx={4} loaded={loaded} cover={cover} />
          <Dividers idx={5} loaded={loaded} cover={cover} />
          <Dividers idx={6} loaded={loaded} cover={cover} />
        </group>
      </group>

      <a.group position={[0, 0.5, 0]}>
        <Optimization position={[0, 1.2, -0.01]} />
        <EmaCrossover
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.275, 0.1, 0.2]}
          position={[-1.7, 0.5, 0.0]}
        />
        <Ema rotation={[Math.PI / 2, 0, 0]} scale={[3.0, 0, 2.4]} position={[0.5, -0.35, -0.25]} />
        <SolcityBG position={[0.05, -0.7, 0]} scale={1} />
        <Among position={[0, -1.6, -1]} />
        <Lain scale={0.75} position={[0, -2.85, -1]} />
        <OctoCat
          scale={32.5}
          position={[-0.05, -3.875, -1]}
          rotation={[Math.PI / 2, 0, 0]}
          maskid={15}
          idx={6}
          loaded={loaded}
          cover={cover}
        />
      </a.group>
    </a.group>
  );
}

function ProjectsMenu({ heartClicked, menuVis, handleProjectClick, cover, loaded, currLoc }) {
  const [location, setLocation] = useLocation();
  const [flip, setFlip] = useState(false);
  const { viewport } = useThree();

  const ProjMenuCoverOpac = useSpring({
    from: { opacity: 1 },
    to: { opacity: location === '/projects' ? 0 : 1 },
    config: config.slow,
    delay: location === '/projects' ? (heartClicked ? 450 : 250) : 0,
  });

  useEffect(() => {
    if (location !== currLoc && location === '/projects') {
      setTimeout(() => {
        setFlip((prev) => !prev);
      }, 250);
    }
  }, [setLocation, location, currLoc]);

  return (
    <a.group>
      <ScrollControls pages={flip ? 1.05 : 1.05001} distance={1} maxSpeed={2}>
        <SetScrollTopToTop send={currLoc !== location} />
        {/* <SetHomeHeight loc={currLoc === '/'} reset={currLoc === location} /> */}
        <HideScrollbar hide={location !== '/projects'} />
        <Scroll>
          <group visible={menuVis}>
            <mesh position={[0, 0, 0]}>
              <planeBufferGeometry args={[viewport.width * 2, viewport.height * 1.25]} />
              <a.meshBasicMaterial color={'#FCEED3'} transparent {...ProjMenuCoverOpac} />
            </mesh>
            <Menu
              menuVis={menuVis}
              handleProjectClick={handleProjectClick}
              cover={cover}
              loaded={loaded}
            />
          </group>
        </Scroll>
      </ScrollControls>
    </a.group>
  );
}

export function ProjectPage({ setProjectsOpened, isProjOpen, heartClicked }) {
  const [location, setLocation] = useLocation();
  const [currLoc, setCurrLoc] = useState(location);
  const [loaded, setLoaded] = useState(false);
  const [menuVis, setMenuVis] = useState(true);
  const [cover, setCover] = useState(false);

  const handleProjectClick = (loc) => {
    if (
      ['/projects', '/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location)
    ) {
      setProjectsOpened(true);
      setCover(true);
      setLocation(loc);
      const timerId = setTimeout(() => {
        setMenuVis(false);
      }, 750);
      return () => {
        clearTimeout(timerId);
      };
    }
  };

  useEffect(() => {
    if (location !== currLoc) {
      setTimeout(() => {
        setCurrLoc(location);
      }, 250);
    }
    if (location === '/projects' && !loaded) {
      setTimeout(() => {
        setMenuVis(true);
        setCover(false);
        setLoaded(true);
        setProjectsOpened(false);
      }, 250);
    } else if (location === '/') {
      setProjectsOpened(false);
      setLoaded(false);
      setCover(false);
      setTimeout(() => {
        setMenuVis(false);
      }, 250);
    } else if (['/optimization', '/emacrossover', '/tasklogger', '/lain', '/solcity'].includes(location)) {
      setLoaded(false);
      setProjectsOpened(true);
      setCover(true);

      setTimeout(() => {
        setMenuVis(false);
      }, 500);
    }
  }, [currLoc, loaded, location, isProjOpen, setProjectsOpened, setLocation]);

  return (
    <>
      <ProjectsMenu
        menuVis={menuVis}
        currLoc={currLoc}
        handleProjectClick={handleProjectClick}
        cover={cover}
        loaded={loaded}
        heartClicked={heartClicked}
      />
    </>
  );
}
