import { useScroll } from '@react-three/drei';
import { useThree, useFrame } from '@react-three/fiber';
import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowDimensions;
}

export const PAGE_VIEWED = {
  closed: false,
  risk: true,
  ema: true,
  solcity: true,
  amogus: true,
  lain: true,
};

export const projectsViewed = (state, action) => {
  switch (action.type) {
    case 'closed':
      return {
        ...state,
        closed: !state.closed,
      };
    case 'risk':
      return {
        ...state,
        risk: !state.risk,
      };
    case 'ema':
      return {
        ...state,
        ema: !state.ema,
      };
    case 'solcity':
      return {
        ...state,
        solcity: !state.solcity,
      };
    case 'amogus':
      return {
        ...state,
        amogus: !state.amogus,
      };
    case 'lain':
      return {
        ...state,
        lain: !state.lain,
      };
    default:
      return state;
  }
};

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [thresholdCrossed, setThresholdCrossed] = useState(windowWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if ((newWidth > 600 && !thresholdCrossed) || (newWidth <= 600 && thresholdCrossed)) {
        setThresholdCrossed(!thresholdCrossed);
      }
      setWindowWidth(newWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [thresholdCrossed]);

  return thresholdCrossed ? true : false;
}

export function useAspectRatio() {
  const [aspectRatio, setAspectRatio] = useState(window.innerWidth / window.innerHeight);
  const [thresholdCrossed, setThresholdCrossed] = useState(aspectRatio > 0.7);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      const newAspectRatio = newWidth / newHeight;

      if (
        (newAspectRatio > 0.7 && !thresholdCrossed) ||
        (newAspectRatio <= 0.7 && thresholdCrossed)
      ) {
        setThresholdCrossed(!thresholdCrossed);
      }
      setAspectRatio(newAspectRatio);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [thresholdCrossed]);
  return thresholdCrossed ? true : false;
}

export function NoSpin({ children }) {
  const ref = useRef();
  const vec = new THREE.Vector3();
  const { camera, mouse } = useThree();
  useFrame(() => {
    camera.position.lerp(vec.set(mouse.x * 0.05, 0, 12.5), 0.1);
    ref.current.rotation.y = THREE.MathUtils.lerp(0, 0, 0.00001);
  });
  return <group ref={ref}>{children}</group>;
}

// switching to fullscreen causes lots of jank to occur with the Canvas scroll container
// this catches it as soon as it attempts to go out of bounds
export const OutOfBounds = () => {
  const data = useScroll();

  const max = (data.fill.scrollHeight / data.el.clientHeight).toFixed(2) / data.pages.toFixed(2);
  const scrollToBottom = () => {
    const maxScrollPosition = data.el.scrollHeight - data.el.clientHeight;
    data.el.scrollTop = Math.floor(maxScrollPosition * 0.8);
    data.el.scrollTop = Math.floor(maxScrollPosition * 0.9);
    const timerId = setTimeout(() => {
      data.el.scrollTop = Math.floor(maxScrollPosition * 0.985);
    }, 10);
    return () => {
      clearTimeout(timerId);
    };
  };

  useFrame(() => {
    const outOfRange = data.visible(max + 0.015, 10);
    if (outOfRange) {
      scrollToBottom();
      data.offset = max;
    }
  });
};

export const SetHeight = ({ height, reset, loc }) => {
  const previousValueRef = useRef(height);
  const [loaded, setLoaded] = useState(false);
  const data = useScroll();
  const maxScrollPosition = data.el.scrollHeight - data.el.clientHeight;
  useEffect(() => {
    const scrollToTop = () => {
      const timerId1 = setTimeout(() => {
        data.el.scrollTop = 10;
      }, 50);
      const timerId2 = setTimeout(() => {
        data.el.scrollTop = 1;
      }, 100);
      return () => {
        clearTimeout(timerId1);
        clearTimeout(timerId2);
      };
    };

    const resetScroll = () => {
      if (data.offset !== data.el.scrollTop / (data.el.scrollHeight - data.el.clientHeight)) {
        const timerId1 = setTimeout(() => {
          data.el.scrollTop = data.offset * (data.el.scrollHeight - data.el.clientHeight);
        }, 150);
        return () => {
          clearTimeout(timerId1);
        };
      }
    };

    data.pages = height / window.innerHeight;

    if (!reset) {
      if (!loaded) {
        if (data.offset > 0 && loc) scrollToTop();

        const timerId1 = setTimeout(() => {
          setLoaded(true);
          previousValueRef.current = height;
        }, 250);
        return () => {
          clearTimeout(timerId1);
        };
      }

      if (height !== previousValueRef.current && loaded && loc) {
        if (data.el.scrollTop > (data.el.scrollHeight - data.el.clientHeight) / 2)
          data.el.scrollTop = data.el.scrollTop - 1;
        else data.el.scrollTop = data.el.scrollTop + 1;
        resetScroll();
        previousValueRef.current = height;
      }
    } else setLoaded(false);
  }, [height, data.fill.style, data.pages, data,data.damping, reset, loaded, maxScrollPosition, loc]);

  const scrollToBottom = () => {
    const timerId = setTimeout(() => {
      data.el.scrollTop = 0;
    }, 50);
    const timerId2 = setTimeout(() => {
      data.el.scrollTop = Math.floor(maxScrollPosition * 0.95);
    }, 75);
    const timerId3 = setTimeout(() => {
      data.el.scrollTop = maxScrollPosition;
    }, 100);
    return () => {
      clearTimeout(timerId);
      clearTimeout(timerId2);
      clearTimeout(timerId3);
    };
  };

  useFrame(() => {
    const outOfRange = data.visible(1.025, 10);
    if (outOfRange) {
      data.offset = 1;
      data.delta = 0;
      scrollToBottom();
    }
  });
};

export const HideScrollbar = ({ hide }) => {
  const data = useScroll();
  useEffect(() => {
    if (hide) {
      data.el.style.overflow = 'hidden'
    }
  }, [hide])
}

export const SetHomeHeight = ({ loc, toBottom, toTop, heartClicked, reset }) => {
  const data = useScroll();

  const windowWidth = useWindowWidth();
  const maxScrollPosition = data.el.scrollHeight - data.el.clientHeight;

  useEffect(() => {
    if (windowWidth && loc) data.pages = 2.2025;
    else if (!windowWidth && loc) data.pages = 1.692;
    else {
      data.pages = 1.05
    };

    const scrollToBottom = () => {
      const timerId = setTimeout(() => {
        data.el.scrollTop = data.el.scrollHeight - data.el.clientHeight;
      }, 150);
      return () => {
        clearTimeout(timerId);
      };
    };

    const scrollToTop = () => {
      const timerId1 = setTimeout(() => {
        data.el.scrollTop = 10;
      }, 0);
      const timerId2 = setTimeout(() => {
        data.el.scrollTop = 1;
      }, 150);
      return () => {
        clearTimeout(timerId1);
        clearTimeout(timerId2);
      };
    };

    const delayedScroll = () => {
      const timerId = setTimeout(() => {
        data.el.scrollTop = 0;
      }, 800);
      const timerId2 = setTimeout(() => {
        data.el.scrollTop = 0;
      }, 810);
      return () => {
        clearTimeout(timerId);
        clearTimeout(timerId2);
      };
    };
    if (toTop && data.el.scrollTop !== 0) scrollToTop();

    if (heartClicked) delayedScroll();

    if (toBottom) scrollToBottom();
  }, [
    data.el.clientHeight,
    data.fill.style,
    data.pages,
    data,
    reset,
    loc,
    windowWidth,
    heartClicked,
    toBottom,
    toTop,
    maxScrollPosition,
  ]);

  const scrollToBottom = () => {
    const timerId = setTimeout(() => {
      data.el.scrollTop = 0;
    }, 50);
    const timerId2 = setTimeout(() => {
      data.el.scrollTop = Math.floor(maxScrollPosition * 0.985);
    }, 75);
    const timerId3 = setTimeout(() => {
      data.el.scrollTop = maxScrollPosition;
    }, 100);
    return () => {
      clearTimeout(timerId);
      clearTimeout(timerId2);
      clearTimeout(timerId3);
    };
  };

  useFrame(() => {
    const outOfRange = data.visible(1.025, 10);
    if (outOfRange) {
      scrollToBottom();
      data.offset = 1;
    }
  });
};

export const ResetScrollTop = ({ send }) => {
  const data = useScroll();

  useEffect(() => {
    const scrollToTop = () => {
      const timerId = setTimeout(() => {
        data.el.scrollTop = data.offset * (data.el.scrollHeight - data.el.clientHeight);
      }, 150);
      return () => {
        clearTimeout(timerId);
      };
    };
    if (data.offset !== data.el.scrollTop / (data.el.scrollHeight - data.el.clientHeight) && send) {
      scrollToTop();
    }
  }, [data.el, data.offset, send]);
};

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

export const SetDistance = ({handle}) => {
  const data = useScroll();

  useEffect(() => {
  }, [data.pages]);
};

export const SetScrollTopToTop = ({ send }) => {
  const data = useScroll();
  
  useEffect(() => {
    const scrollToTop = () => {
      const timerId1 = setTimeout(() => {
        data.el.scrollTop = 10;
      }, 50);
      const timerId2 = setTimeout(() => {
        data.el.scrollTop = 0;

      }, 100);
      return () => {
        clearTimeout(timerId1);
        clearTimeout(timerId2);
      };
    };

    if (send && data.el.scrollTop !== 0) {
      scrollToTop();
    } else if (
      data.offset !== data.el.scrollTop / (data.el.scrollHeight - data.el.clientHeight) &&
      send
    ) {
      data.el.scrollTop = 1;
      scrollToTop();
    }
  }, [send, data.el, data.offset, data.pages]);
};

export const SetScrollTopToBottom = ({ send }) => {
  const data = useScroll();

  useEffect(() => {
    const scrollToBottom = () => {
      const timerId = setTimeout(() => {
        data.el.scrollTop = data.el.scrollHeight - data.el.clientHeight;
      }, 150);

      return () => {
        clearTimeout(timerId);
      };
    };
    if (send) {
      scrollToBottom();
    }
  }, [send, data.el]);
};
